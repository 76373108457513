import { ErrorCustomActionTypes } from '@/store/modules/errorCustom/action-types';
import { IErrorContext, IErrorCustom } from '@/types/IErrorCustom';
import { store } from '../store/index';

import router from '@/router';
import { IClothing, IClothingContentRulesMetadata, IClothingDetails, IClothingOption, IClothingOptionType, IClothingOptionValue, IClothingType, IDisplayCondition } from '@/types/IClothing.interface';
import { IPurchase, IPurchaseDetail, IPurchaseToPurchaseStatus } from '@/types/IPurchase.interface';
import { UserActionTypes } from '@/store/modules/user/action-types';
import { IErrorFromService } from '@/types/IErrorFromService.interface';
import { NavigationActionTypes } from '@/store/modules/navigation/action-types';
import { RouterEnum } from '@/router/router.enum';
import { ILoadingRessourceInformation, INavigation } from '@/types/INavigation.interface';
import { IUser } from '@/types/IUser.interface';
import { IShop } from '@/types/IShop.interface';
import { IFabricFieldInfo, IStock, StockType } from '@/types/IStock';
import constantes, { ClothingSlug, FabricSlug, InputType, MeasurementSlug, SatinOrTissuSecondaire } from '@/constantes/constantes';
import { IMeasurementType } from '@/types/IMeasurement.interface';

export default {
    isUserConnected(): boolean {
        const accessToken = store.getters["user/token"] as string;
        if (accessToken && accessToken !== "") {
            return true;
        } else {
            return false;
        }
    },
    isAdminConnected(): boolean {
        const accessToken = store.getters["user/token"] as string;
        const isAdmin = store.getters["user/isAdmin"] as boolean;
        //console.log("isAdmin", isAdmin);
        if (accessToken && accessToken !== "" && isAdmin) {
            return true;
        } else {
            return false;
        }
    },
    isChainSupplyConnected(): boolean {
        const accessToken = store.getters["user/token"] as string;
        const isChainSupply = store.getters["user/isChainSupply"] as boolean;
        //console.log("isAdmin", isAdmin);
        if (accessToken && accessToken !== "" && isChainSupply) {
            return true;
        } else {
            return false;
        }
    },
    isShopSelected(): boolean {
        const shop = store.getters["user/currentShop"] as IShop;
        if (shop && shop.id > 0) {
            return true;
        } else {
            return false;
        }
    },
    getRequestOption(method: string, stringified_body: string): RequestInit {
        let request :RequestInit;
        const accessToken = store.getters["user/token"] as string;
        const shop = store.getters["user/currentShop"] as IShop;
        if (accessToken && accessToken !== "" && shop && shop.id > 0) {
            request = {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : "Bearer " + accessToken,
                    "shopId": String(shop.id),
                    "ngrok-skip-browser-warning": true,
                } as any,
            } as RequestInit;
        } else if (accessToken && accessToken !== "") {
            request = {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : "Bearer " + accessToken,
                    "ngrok-skip-browser-warning": true
                } as any,
            } as RequestInit;
        } else {
            request = {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": true
                } as any,
            } as RequestInit;
        }
        if (stringified_body && stringified_body !== "") {
            request.body = stringified_body;
        }
        return request;
    },
    getRequestOptionFile(method: string, file: File, key: string): RequestInit {
        let request :RequestInit;
        const accessToken = store.getters["user/token"] as string;
        const shop = store.getters["user/currentShop"] as IShop;
        if (accessToken && accessToken !== "" && shop && shop.id > 0) {
            request = {
                method: method,
                headers: {
                    //"Content-Type": "application/json",
                    "Authorization" : "Bearer " + accessToken,
                    "shopId": String(shop.id),
                },
            } as RequestInit;
        } else if (accessToken && accessToken !== "") {
            request = {
                method: method,
                headers: {
                    //"Content-Type": "application/json",
                    "Authorization" : "Bearer " + accessToken,
                },
            } as RequestInit;
        } else {
            request = {
                method: method,
                // headers: {
                //     "Content-Type": "application/json"
                // },
            } as RequestInit;
        }
        if (file && file !== undefined) {
            const formData = new FormData();
            formData.append(key, file, file.name);
            request.body = formData;
        }
        return request;
    },
    logError(errorCode: string, errorMessage: string, serviceName: string, methodName: string, payload: Object) {
        store.dispatch(ErrorCustomActionTypes.ERROR_CUSTOM_LOG_ERROR, {
            code: errorCode,
            message: errorMessage,
            date: new Date(),
            user: store.getters["user/currentUser"],
            context: {
                serviceName: serviceName,
                methodName: methodName,
                payload: payload
            } as IErrorContext
        } as IErrorCustom);
        //console.log("store => ", store);
    },
    fetchRessource(url: string, requestOptions: RequestInit, payload: Object, serviceName: string, methodName: string) {
        try {
            //console.log("here 1", url, store.getters["navigation/loadingRessources"]);
            store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                url: url,
                isLoading: true
            } as ILoadingRessourceInformation);
            return fetch(url, requestOptions).then(data => data.json()).then(data => {
                if (data.message) {
                    //console.log(" we have a data.message", data);
                    data.date = (new Date()).toLocaleString();
                    data.displayDetails = false;
                    store.dispatch(ErrorCustomActionTypes.ERROR_NEW_ERROR, data as IErrorFromService);

                    if (data.statusCode && data.statusCode === 401) {
                        // redirect to login page (unauthorized error)
                        if (router.currentRoute.value.name !== "login") {
                            store.dispatch(UserActionTypes.USER_LOGOUT);
                            store.dispatch(NavigationActionTypes.NAVIGATION_NAVIGATE_BACKWARD, {
                                label: RouterEnum.LOGIN_LABEL,
                                name: RouterEnum.LOGIN_NAME,
                                path: RouterEnum.LOGIN_PATH,
                                canModifyData: false,
                            } as INavigation);
                        }
                    }
                }
                //console.log("here 2");
                store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                    url: url,
                    isLoading: false
                } as ILoadingRessourceInformation);
                return data;
            }).catch(error => {
                //console.log("here 3");
                this.logError(error.name, error.message, serviceName, methodName, payload);
                store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                    url: url,
                    isLoading: false
                } as ILoadingRessourceInformation);
                throw (error as Error);
            });
        } catch (error) {
            //console.log("here 4");
            store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                url: url,
                isLoading: false
            } as ILoadingRessourceInformation);
            this.logError((error as Error).name, (error as Error).message, serviceName, methodName, payload);
            throw (error as Error);
        } 
    },
    fetchRessourceWithoutAnswer(url: string, requestOptions: RequestInit, payload: Object, serviceName: string, methodName: string) {
        try {
            //console.log("here 11", url, store.getters["navigation/loadingRessources"]);
            store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                url: url,
                isLoading: true
            } as ILoadingRessourceInformation);
            fetch(url, requestOptions)
            .then(() => {
                store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                    url: url,
                    isLoading: false
                } as ILoadingRessourceInformation);
            })
            .catch(error => {
                store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                    url: url,
                    isLoading: false
                } as ILoadingRessourceInformation);
                //console.log("error 1", error);
                this.logError(error.name, error.message, serviceName, methodName, payload);
                throw (error as Error);
            });
        } catch (error) {
            store.dispatch(NavigationActionTypes.NAVIGATION_SET_LOADING_IN_PROGRESS, {
                url: url,
                isLoading: false
            } as ILoadingRessourceInformation);
            //console.log("error 2", error);
            this.logError((error as Error).name, (error as Error).message, serviceName, methodName, payload);
            throw (error as Error);
        } 
    },
    isPurchaseDetailComplete(clothingOptionType: IClothingOptionType, purchaseDetail: IPurchaseDetail): IClothingOption[] {
        if (!clothingOptionType.active) {
            return [] as IClothingOption[];
        }
        let result = false;
        
        // check if value is present in the activePurchaseDetail
        const tabOptionsInError = [] as IClothingOption[];
        //console.log(purchaseDetail);

        
        const _clothingDetails =  this.getClothingDetailsFromClothingOptionTypeId(clothingOptionType.id);
        // const _clothingOptionTypeIsOption = _clothingDetails?.clothingOptionTypes.filter(cot => cot.rules.isOption);
        // const tabOptionsRequiredWithoutValues = [] as IClothingOption[];
        // let nbOptionRequired = 0;
        // if (_clothingDetails && _clothingOptionTypeIsOption) {
        //     for (const cot of _clothingOptionTypeIsOption) {
        //         for (const co of cot.clothingOptions) {
        //             if (co.rules.isRequired) {
        //                 nbOptionRequired++;
        //                 const detail = (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail.filter(cotpd => cotpd.clothingOptionId === co.id);   
        //                 // console.log("----------- ", co.slug, co.id, detail, (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail, clothingOptionType.clothingOptions);
        //                 if ((!detail || detail.length <= 0 || detail[0].value === "") && (co.inputType !== "boolean") && co.rules.isRequired) {
        //                     // if in error, we should check if the slug should not be hidden
        //                     // but we have to check all the clothingOptions of the clothingOptionType
        //                     // sameLineItem - check if the slug sould be disabled to not raise error

        //                     //console.log("isPurchaseDetailComplete", co.slug, this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType), this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType), this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType));

        //                     if (!this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType) &&
        //                         !this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType) &&
        //                         !this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType) &&
        //                         !this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails)
        //                         ) {
        //                             //this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails);
        //                             // console.log(
        //                             //     "tabOptionsRequiredWithoutValues => ", 
        //                             //     co.slug,
        //                             //     this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType), 
        //                             //     this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType),
        //                             //     this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType)
        //                             // );
        //                             tabOptionsRequiredWithoutValues.push(co);
        //                     } else {
        //                         nbOptionRequired--;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }


        for (const co of clothingOptionType.clothingOptions) {
            //console.log("========>",  co.slug);
            const detail = (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail.filter(cotpd => cotpd.clothingOptionId === co.id);   
            //console.log("=====----------- ", co.slug, co.id, detail, (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail, clothingOptionType.clothingOptions);
            
            if (!this.isSlugSelectMultiCompletedOrEmpty(purchaseDetail, co)) {
                //console.log("error select_multiple slug not completed => ", co.slug, detail, purchaseDetail);//, tabOptionsRequiredWithoutValues, nbOptionRequired);
                tabOptionsInError.push(co);
            }
            else if (!this.isSlugCompleted(co.slug, purchaseDetail, co) &&
            !this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType) &&
            !this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails)
            ) {
                //console.log("hahahaha", this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails));
                //console.log("error checkbox slug not completed => ", co.slug, detail, purchaseDetail);//, tabOptionsRequiredWithoutValues, nbOptionRequired);
                tabOptionsInError.push(co);
            } else if ((!detail || detail.length <= 0 || detail[0].value === "") && (co.inputType !== "boolean") && co.rules.isRequired) {
              // if in error, we should check if the slug should not be hidden
              // but we have to check all the clothingOptions of the clothingOptionType
              // sameLineItem - check if the slug sould be disabled to not raise error

            //   console.log("isPurchaseDetailComplete", co.slug, 
            //     this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType), 
            //     this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType), 
            //     this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType),
            //     this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails));

              //this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails);
              //if (clothingOptionType.slug.includes("veste-boutonniere")) {
                // console.log("test - isPurchaseDetailComplete", co.slug, 
                //     this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType), 
                //     this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType), 
                //     this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType),
                //     this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails));
              //}
                
              if (!this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType) &&
                !this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType) &&
                !this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType) &&
                !this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails) 
                ) {
                
                    console.log(
                        "isPurchaseDetailComplete => ", 
                        co.slug,
                        this.isSlugShouldBeHidden(co.slug, purchaseDetail, clothingOptionType), 
                        this.isSlugShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType),
                        this.isSlugSatinOrTissuSecondaireShouldBeDisabled(co.slug, purchaseDetail, clothingOptionType),
                        this.isSlugShouldBeHidden2(co.slug, purchaseDetail, _clothingDetails)
                    );

                if (!clothingOptionType.rules.isOption) { // || (tabOptionsRequiredWithoutValues.length > 0 && tabOptionsRequiredWithoutValues.length !== nbOptionRequired)) {
                    //console.log("error => ", co.slug, detail, purchaseDetail);//, tabOptionsRequiredWithoutValues, nbOptionRequired);
                    tabOptionsInError.push(co);
                } else {
                    //console.log("on est sur une option => ", co.slug, clothingOptionType, (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail);  
                    let isOk = true;
                    for (const _co of clothingOptionType.clothingOptions) {
                        if (isOk === true) {
                            const _details = (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === _co.id);
                            if (_details) {
                                isOk = false;
                            }
                        }
                    }
                    if (!isOk) {
                        //console.log("error => ", co.slug, detail, purchaseDetail);
                        tabOptionsInError.push(co);
                    }
                }
              }
            } else {
                // we check if it's a fabric field and if the stock is ok
                const result = this.isFabricField(co.rules.metadata);


                if (result.isFabricField) {
                    if (detail && detail.length > 0) {
                        const stock = this.getStock(detail[0].value, result.typeStock);
                        //console.log("fabric field ", co.slug, detail[0].value);
                        if (stock === undefined) {
                            tabOptionsInError.push(co);
                        }
                        // else {
                        // if we will need to check the quantity to say ok, it can be done here    
                        //     console.log(stock);
                        // }
                    }
                }
            }
        }
        
        
        // console.log(tabOptionsInError);
        if (tabOptionsInError.length > 0) {
          result = false;
        } else {
          result = true;
        }
        //console.log("result => ", tabOptionsInError, result);

        return tabOptionsInError;
    },
    isSlugCompleted(slug: string, purchaseDetail: IPurchaseDetail, clothingOption: IClothingOption) {
        let result = true;
        //console.log("isSlugCompleted", slug, clothingOption);
        if (clothingOption.inputType === "checkbox" && clothingOption.rules.isRequired) {
            
            const _data = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingOption.id);
            //console.log("isSlugCompleted", slug, _data);
            if (!_data) {
                result = false;
            } else if (!_data.value.includes(":1")) {
                result = false;
            }
        }
        return result;
    },
    isSlugSelectMultiCompletedOrEmpty(purchaseDetail: IPurchaseDetail, clothingOption: IClothingOption): boolean {
        let result = true;
        if (clothingOption.inputType === "select_multiple") {
            const _data = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingOption.id);
            //console.log("isSlugCompleted select_multiple", _data, clothingOption);

            if (_data && _data.value && _data.value !== "") {
                for (const v of _data.value.split(",")) {
                    const _cov = clothingOption.clothingOptionValues.find(cov => String(cov.value) === String(v.split(":")[0]));
                    //console.log(" =========== isSlugSelectMultiCompletedOrEmpty ", v, _cov);
                    if (_cov && 
                        ((_cov.rules?.metadata?.limitedStock &&_cov.rules?.metadata?.limitedStock !== "") || 
                        (_cov.rules?.metadata?.color &&_cov.rules?.metadata?.color !== "")
                        ) &&
                        !v.includes(":")) {
                            //console.log("we have something uncomplete !!!", v, _cov);
                            result = false;
                        }
                }
            }
        }
        return result;
    },
    isSlugShouldBeDisabled(slug: string, purchaseDetail: IPurchaseDetail, clothingOptionType: IClothingOptionType): boolean {
        for (const cotpd of (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail) {
            for (const co of clothingOptionType.clothingOptions) {
              // we look for input type boolean, id value is true, we check if it's an item on the same line
              if (co.id === cotpd.clothingOptionId) {
                //const value = co.clothingOptionValues.filter(cov => String(cov.value) === cotpd.value);
                if (cotpd.value === 'true') {
                    for (const co2 of clothingOptionType.clothingOptions) {
                        if (co2.ihmItemsOnTheSameLine) {
                            if (co2.slug === slug) {
                                return true;
                            }
                        }
                    }
                }
              }
            }
        }
        return false;
    },
    isSlugSatinOrTissuSecondaireShouldBeDisabled(slug: string, purchaseDetail: IPurchaseDetail, clothingOptionType: IClothingOptionType): boolean {
        
        if (slug.indexOf(SatinOrTissuSecondaire.REFERENCE_TISSU_SECONDAIRE) > 0) {
            for (const cotpd of (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail) {
                for (const co of clothingOptionType.clothingOptions) {
                  // we look for input type boolean, id value is true, we check if it's an item on the same line
                  if (co.id === cotpd.clothingOptionId && co.slug.indexOf(SatinOrTissuSecondaire.REFERENCE_SATIN) > 0) {
                    // console.log("idSlugSatinOrTissuSecondaireShouldBeDisabled",slug, true);
                    return true;
                  }
                }
            }
        } else if (slug.indexOf(SatinOrTissuSecondaire.REFERENCE_SATIN) > 0) {
            for (const cotpd of (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail) {
                for (const co of clothingOptionType.clothingOptions) {
                  // we look for input type boolean, id value is true, we check if it's an item on the same line
                  if (co.id === cotpd.clothingOptionId && co.slug.indexOf(SatinOrTissuSecondaire.REFERENCE_TISSU_SECONDAIRE) > 0) {
                    // console.log("idSlugSatinOrTissuSecondaireShouldBeDisabled",slug, true);
                    return true;
                  }
                }
            }
        }
        return false;
    },
    isSlugShouldBeHidden(slug: string, purchaseDetail: IPurchaseDetail, clothingOptionType: IClothingOptionType): boolean {
        let result = false;

        
        for (const cotpd of (purchaseDetail as IPurchaseDetail).clothingOptionToPurchaseDetail) {
          for (const co of clothingOptionType.clothingOptions) {
            //console.log(co.id, cotpd.clothingOptionId, co.inputType, cotpd);
            if (co.id === cotpd.clothingOptionId && co.inputType === "radio") {
              const value = co.clothingOptionValues.filter(cov => String(cov.value) === cotpd.value);
              if (value && value.length > 0) {
                // we found the slug for the clothingOptionToPurchaseDetail value
                // now we can look into rules.displayConditions.hideElement to find the slug
                const dc = co.rules.displayConditions.filter(dc => dc.optionValue === value[0].slug);
                //console.log(dc);
                if (dc && dc.length > 0 && dc[0].hideElements) {
                  const tabSlug = dc[0].hideElements.split("|");
                  const filteredSlugs = tabSlug.filter(s => s === slug);
                  if (filteredSlugs && filteredSlugs.length > 0) {
                    result = true;
                  }
                }
              }
            } else {
                if (co.inputType === "radio" || co.inputType === "checkbox") {
                    // we have to use the cotpd.id to find the rules of this id, and see if the slug should be hidden
                    const activeClothingDetails = store.getters["clothing/activeClothingDetails"] as IClothingDetails;
                    if (activeClothingDetails && activeClothingDetails.clothingOptionTypes) {
                        for (const cd of activeClothingDetails.clothingOptionTypes) {
                            
                            for (const co of cd.clothingOptions) {
                                if (cotpd.clothingOptionId === co.id) {
                                    const clothingOptionValueSelected = co.clothingOptionValues.find(cov => String(cov.value) === cotpd.value);
                                    if (clothingOptionValueSelected) {
                                        const dc = co.rules.displayConditions.find(dc => dc.optionValue === clothingOptionValueSelected.slug);
                                        if (dc && dc.hideElements) {
                                            const tabSlug = dc.hideElements.split("|");
                                            const filteredSlugs = tabSlug.filter(s => s === slug);
                                            if (filteredSlugs && filteredSlugs.length > 0) {
                                                result = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
          }
        }
        
        return result;
    },
    checkValue(co: IClothingOption, dc: IDisplayCondition, clothingDetails: IClothingDetails, purchaseDetail: IPurchaseDetail, display: boolean): boolean {
        let result = false;
        // now we need to see if the value is selected
        let valueToCheck = "";
        //for (const dc of _displayConditions) {
            switch (co.inputType) {
                case InputType.TEXT:
                    //console.log("text");
                    valueToCheck = dc.textValue;    
                    break;
                case InputType.BOOLEAN:
                    //console.log("c'est la merde.... on a un bool", dc);
                    break;
                case InputType.CHECKBOX:
                    //console.log("checkbox");
                    valueToCheck = dc.checkboxValue;
                    break;
                case InputType.RADIO:
                    valueToCheck = dc.optionValue;
                    break;
                case InputType.SELECT:
                    //console.log("select");
                    valueToCheck = dc.selectValue;
                    break;
                case InputType.SELECT_MULTIPLE:
                    //console.log("selectMultiple");
                    valueToCheck = dc.selectValue;
                    break;
                default:
                    break;
            }
        //}
        //console.log("isSlugShouldBeHidden2", co.inputType, valueToCheck, dc);

        // we need to find the clothingOptionId for the slug, to check if it has a value in the activePurchase
        let valueId = this.getClothingIdFromSlug(valueToCheck, clothingDetails);
        
        //console.log("checkValue step 1", valueToCheck, valueId, purchaseDetail.clothingOptionToPurchaseDetail);
        if (valueId > 0) {
            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === valueId);
            if (_clothingOptionToPurchaseDetail && !display) {
                //console.log("checkValue 1 true", valueToCheck);
                result = true;
            } else if (display && !_clothingOptionToPurchaseDetail) {
                if (co.inputType === InputType.RADIO) {
                    const subValueId = this.getClothingIdFromSlugForRadio(valueToCheck, clothingDetails);
                    const _cotpd = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === subValueId);
                    //console.log("checkValue step 3", valueToCheck, _cotpd, subValueId, display, purchaseDetail);
                    if (!_cotpd) {
                        //console.log("checkValue 2 true", valueToCheck);
                        result = true;
                    } else {
                        const _secondCov = this.getClothingOptionValueForValueCheck(purchaseDetail, subValueId, valueId);
                        //console.log("checkValue step 99", _secondCov, _cotpd);
                        if (String(_cotpd.value) !== String(_secondCov?.value)) {
                            //console.log("checkValue 3 true", valueToCheck);
                            result = true;
                        }
                    }
                } else {
                    //console.log("checkValue 4 true", valueToCheck);
                    result = true;
                }
            }
            //console.log("checkValue step 2", valueToCheck, _clothingOptionToPurchaseDetail, display);
        }
        //console.log("checkValue step 3 again", valueToCheck, display, result);
        return result;
    },
    getClothingOptionValueForValueCheck(purchaseDetail: IPurchaseDetail, valueId: number, subValueId: number): IClothingOptionValue | undefined{
        const clothingDetails = store.getters["clothing/clothingDetails"] as IClothingDetails[];
        const targetClothingDetails = clothingDetails.find(cd => cd.id === purchaseDetail.clothingId);
        let result : IClothingOptionValue| undefined;
        if (targetClothingDetails && targetClothingDetails.assembly && targetClothingDetails.assembly.length === 1) {
            // not on costume
            //console.log("getClothingOptionValueForValueCheck 1", targetClothingDetails);
            for (const cot of targetClothingDetails.clothingOptionTypes) {
                const targetClothingOption = cot.clothingOptions.find(co => co.id === valueId);
                if (targetClothingOption) {
                    const targetClothingOptionValue = targetClothingOption.clothingOptionValues.find(cov => cov.id === subValueId);
                    if (targetClothingOptionValue) {
                        result = targetClothingOptionValue;
                        return result;
                    }
                }
            }
        } else if (targetClothingDetails && targetClothingDetails.assembly && targetClothingDetails.assembly.length > 1) {
            // on a costume
            for (const assemblyItem of targetClothingDetails.assembly) {
                const targetClothingAssembly = clothingDetails.find(cd => cd.id === assemblyItem);
                if (targetClothingAssembly ) {
                    // not on costume
                    for (const cot of targetClothingAssembly.clothingOptionTypes) {
                        const targetClothingOption = cot.clothingOptions.find(co => co.id === valueId);
                        if (targetClothingOption) {
                            const targetClothingOptionValue = targetClothingOption.clothingOptionValues.find(cov => cov.id === subValueId);
                            if (targetClothingOptionValue) {
                                result = targetClothingOptionValue;
                                return result;
                            }
                        }
                    }
                }
            }
        }
        return result;
    },
    isSlugShouldBeHidden2(slug: string, purchaseDetail: IPurchaseDetail, clothingDetails: IClothingDetails | undefined): boolean {
        let result = false;
        //console.log('isSlugShouldBeHidden2', slug);
        if (clothingDetails) {
            for (const cot of clothingDetails.clothingOptionTypes) {
                if (result === false) {
                    for (const co of cot.clothingOptions) {
                        if (result === false) {
                            // if (slug === "gilet-emplacement-satin-ou-tissu-secondaire-choix") {
                            //     console.log("isSlugShouldBeHidden2", slug, co.rules.displayConditions);
                            // }
                            const dc = co.rules.displayConditions.find((dc) => {
                                return (dc.displayElements && dc.displayElements.includes(slug) && !dc.displayElements.includes(slug + '-'));
                            });
                            if (dc) {
                                // // we have in the displayElements the slug that we are checking
                                // // now we need to see if the value is selected
                                result = this.checkValue(co, dc, clothingDetails, purchaseDetail, true);
                                //if (slug === "veste-boutonniere-revers-de-col-choix") {
                                    //console.log("----------- isSlugShouldBeHidden2 log 1 => ", slug, result, dc);
                                //}
                                
                            }

                            //console.log('isSlugShouldBeHidden2 - 1', slug, result);
                            if (result === false) {
                                const dc2 = co.rules.displayConditions.find((dc) => {
                                    return (dc.hideElements && dc.hideElements.includes(slug) && !dc.hideElements.includes(slug + '-'));
                                });
                                if (dc2) {
                                    //console.log("isSlugShouldBeHidden2 suite", slug, co.slug, dc2);
                                    // we have in the hideElements the slug that we are checking
                                    // now we need to see if the value is selected
                                    result = this.checkValue(co, dc2, clothingDetails, purchaseDetail, false);
                                    //console.log("----------- isSlugShouldBeHidden2 log 2 => ", slug, result, dc2);
                                }
                            }

                            //console.log('isSlugShouldBeHidden2 - 2', slug, result);
                            if (result === false ) {
                                // for select multi, we have to check if a display condition with a hideElement equal to the clothingOptionType (parent of the slug we are checking) slug
                                //console.log("----- slug ----", slug);
                                for (const cot2 of clothingDetails.clothingOptionTypes) {
                                    if (cot2.slug === slug) {
                                        const dc3 = co.rules.displayConditions.filter( _displayCondition => _displayCondition.hideElements?.includes(cot2.slug) && !_displayCondition.hideElements?.includes(cot2.slug + '-'));
                                                // if (slug === "gilet-dos" && dc3.length > 0) {
                                                //     console.log("dc3 => ", cot2.slug, slug, dc3);
                                                // }
                                                for (const condition of dc3) {
                                                    if (result === false) {
                                                        const clothingId =this.getClothingIdFromSlug(condition.selectValue, clothingDetails);
                                                        // if (slug === "veste-boutonniere-revers-de-col") {
                                                             //console.log("dc3_1 => ", cot2.slug, slug, clothingId, purchaseDetail.clothingOptionToPurchaseDetail);
                                                        // }
                                                        const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                        if (_clothingOptionToPurchaseDetail) {
                                                            result = true;
                                                            //console.log("----------- isSlugShouldBeHidden2 log 3 => ", slug, result);
                                                        }
                                                        if (result === false) {
                                                            const clothingId =this.getClothingIdFromSlugForRadio(condition.selectValue, clothingDetails);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                                 //console.log("dc3_2 => ", cot2.slug, slug, clothingId, purchaseDetail.clothingOptionToPurchaseDetail);
                                                            // }
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            if (_clothingOptionToPurchaseDetail ) {
                                                                
                                                                const _cov = this.getClothingOptionValueFromSlug(condition.selectValue, clothingDetails);
                                                                //console.log('on est pas si mal', _clothingOptionToPurchaseDetail, _cov);
                                                                if (_cov) {
                                                                    const tabCotpdValues = _clothingOptionToPurchaseDetail.value.split(",");
                                                                    if (tabCotpdValues.find(item => String(item) === String(_cov.value))) {
                                                                        result = true;
                                                                    }
                                                                    if (result === false) {
                                                                        
                                                                        for (const _v of tabCotpdValues) {
                                                                            const _secondSplit = _v.split(':');
                                                                            //console.log("on est la maintenant", slug, tabCotpdValues, _secondSplit, _cov.value, _secondSplit.find(item => String(item) === String(_cov.value)));
                                                                            if(_secondSplit.find(item => String(item) === String(_cov.value))) {
                                                                                result = true;
                                                                                //console.log('on est a true!!!');
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                
                                                            }
                                                        }

                                                        if (result === false) {
                                                            // we check the condition.checkboxValue
                                                            const clothingId = this.getClothingIdFromSlugForRadio(condition.checkboxValue, clothingDetails);
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                            //     console.log("dc3_3 => ", cot2.slug, slug, dc3, clothingId, _clothingOptionToPurchaseDetail);
                                                            // }
                                                            const _cov = this.getClothingOptionValueFromSlug(condition.checkboxValue, clothingDetails);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                            //     console.log("dc3_3 => ", cot2.slug, slug, dc3, clothingId, _clothingOptionToPurchaseDetail, _cov);
                                                            // }
                                                            if (_clothingOptionToPurchaseDetail && _cov && String(_clothingOptionToPurchaseDetail.value).includes(_cov.id + ":1")) { //+ _cov.value)) {
                                                                result = true;
                                                                //console.log("----------- isSlugShouldBeHidden2 log 3-2 => ", slug, result, condition.checkboxValue, _cov, _clothingOptionToPurchaseDetail);
                                                            }
                                                        }

                                                        if (result === false) {
                                                            // we check the condition.optionValue
                                                            const clothingId = this.getClothingIdFromSlugForRadio(condition.optionValue, clothingDetails);
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                            //     console.log("dc3_4 => ", condition.optionValue, cot2.slug, slug, dc3, clothingId, _clothingOptionToPurchaseDetail);
                                                            // }
                                                            const _cov = this.getClothingOptionValueFromSlug(condition.optionValue, clothingDetails);
                                                            if (_clothingOptionToPurchaseDetail && _cov && String(_cov.value) === String(_clothingOptionToPurchaseDetail.value)) {
                                                                result = true;
                                                                //console.log("----------- isSlugShouldBeHidden2 log 4 => ", slug, _clothingOptionToPurchaseDetail,result);
                                                            }
                                                        }
                                                    }
                                                }

                                                if (result === false) {
                                                    const dc4 = co.rules.displayConditions.filter( _displayCondition => _displayCondition.displayElements?.includes(cot2.slug) && !_displayCondition.displayElements?.includes(cot2.slug + '-'));
                                                    // if (slug === "veste-boutonniere-revers-de-col") {
                                                        //console.log("dc4 => ", cot2.slug, slug, dc4);
                                                    // }
                                                    for (const condition of dc4) {
                                                        if (result === false) {
                                                            const clothingId =this.getClothingIdFromSlug(condition.selectValue, clothingDetails);
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            if (!_clothingOptionToPurchaseDetail) {

                                                                const clothingId2 =this.getClothingIdFromSlugForRadio(condition.optionValue, clothingDetails);
                                                                const _clothingOptionToPurchaseDetail2 = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId2);
                                                                if (!_clothingOptionToPurchaseDetail2) {
                                                                    result = true;
                                                                    //console.log("----------- isSlugShouldBeHidden2 log 5 => ", slug, result, dc4);
                                                                }

                                                                //result = true;
                                                                //console.log("isSlugShouldBeHidden2 log 4 => ", slug, result, dc4);
                                                            }

                                                            
                                                        }
                                                    }
                                                }
                                    }
                                    if (result === false) {
                                        for (const co2 of cot2.clothingOptions) {
                                            if (co2.slug === slug) {
                                                const dc3 = co.rules.displayConditions.filter( _displayCondition => _displayCondition.hideElements?.includes(cot2.slug) && !_displayCondition.hideElements?.includes(cot2.slug + '-'));
                                                // if (slug === "veste-boutonniere-revers-de-col" && dc3.length > 0) {
                                                //     console.log("dc3 => ", cot2.slug, slug, dc3);
                                                // }
                                                for (const condition of dc3) {
                                                    if (result === false) {
                                                        const clothingId =this.getClothingIdFromSlug(condition.selectValue, clothingDetails);
                                                        // if (slug === "veste-boutonniere-revers-de-col") {
                                                        //     console.log("dc3_1 => ", cot2.slug, slug, co2.slug, clothingId);
                                                        // }
                                                        const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                        if (_clothingOptionToPurchaseDetail) {
                                                            result = true;
                                                            //console.log("----------- isSlugShouldBeHidden2 log 3 => ", slug, result);
                                                        }
                                                        if (result === false) {
                                                            const clothingId =this.getClothingIdFromSlugForRadio(condition.selectValue, clothingDetails);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                            //     console.log("dc3_2 => ", cot2.slug, slug, co2.slug, clothingId);
                                                            // }
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            if (_clothingOptionToPurchaseDetail ) {
                                                                const _cov = this.getClothingOptionValueFromSlug(condition.selectValue, clothingDetails);
                                                                if (_cov) {
                                                                    const tabCotpdValues = _clothingOptionToPurchaseDetail.value.split(",");
                                                                    if (tabCotpdValues.find(item => String(item) === String(_cov.value))) {
                                                                        result = true;
                                                                        //console.log("----------- isSlugShouldBeHidden2 log 3-1 => ", slug, result, _clothingOptionToPurchaseDetail, condition, _cov);
                                                                    }
                                                                }
                                                                
                                                            }
                                                        }

                                                        if (result === false) {
                                                            // we check the condition.checkboxValue
                                                            const clothingId = this.getClothingIdFromSlugForRadio(condition.checkboxValue, clothingDetails);
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                            //     console.log("dc3_3 => ", cot2.slug, slug, dc3, clothingId, _clothingOptionToPurchaseDetail);
                                                            // }
                                                            const _cov = this.getClothingOptionValueFromSlug(condition.checkboxValue, clothingDetails);
                                                            if (_clothingOptionToPurchaseDetail && _cov && String(_clothingOptionToPurchaseDetail.value).includes(_cov.id + ":1")) { //+ _cov.value)) {
                                                                result = true;
                                                                //console.log("----------- isSlugShouldBeHidden2 log 3-2 => ", slug, result, condition.checkboxValue, _cov, _clothingOptionToPurchaseDetail);
                                                            }
                                                        }

                                                        if (result === false) {
                                                            // we check the condition.optionValue
                                                            const clothingId = this.getClothingIdFromSlugForRadio(condition.optionValue, clothingDetails);
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            // if (slug === "veste-boutonniere-revers-de-col") {
                                                            //     console.log("dc3_4 => ", condition.optionValue, cot2.slug, slug, dc3, clothingId, _clothingOptionToPurchaseDetail);
                                                            // }
                                                            const _cov = this.getClothingOptionValueFromSlug(condition.optionValue, clothingDetails);
                                                            if (_clothingOptionToPurchaseDetail && _cov && String(_cov.value) === String(_clothingOptionToPurchaseDetail.value)) {
                                                                result = true;
                                                                //console.log("----------- isSlugShouldBeHidden2 log 4 => ", slug, _clothingOptionToPurchaseDetail,result);
                                                            }
                                                        }
                                                    }
                                                }

                                                if (result === false) {
                                                    const dc4 = co.rules.displayConditions.filter( _displayCondition => _displayCondition.displayElements?.includes(cot2.slug) && !_displayCondition.displayElements?.includes(cot2.slug + '-'));
                                                    // if (slug === "veste-boutonniere-revers-de-col") {
                                                         //console.log("dc4 - again => ", cot2.slug, slug, dc4);
                                                    // }
                                                    for (const condition of dc4) {
                                                        if (result === false) {
                                                            const clothingId =this.getClothingIdFromSlug(condition.selectValue, clothingDetails);
                                                            const _clothingOptionToPurchaseDetail = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId);
                                                            if (!_clothingOptionToPurchaseDetail) {

                                                                const clothingId2 =this.getClothingIdFromSlugForRadio(condition.optionValue, clothingDetails);
                                                                const _clothingOptionToPurchaseDetail2 = purchaseDetail.clothingOptionToPurchaseDetail.find(cotpd => cotpd.clothingOptionId === clothingId2);
                                                                if (!_clothingOptionToPurchaseDetail2) {
                                                                    result = true;
                                                                    //console.log("----------- isSlugShouldBeHidden2 log 5 => ", slug, result, dc4);
                                                                }

                                                                //result = true;
                                                                //console.log("isSlugShouldBeHidden2 log 4 => ", slug, result, dc4);
                                                            }

                                                            
                                                        }
                                                    }

                                                    if (result === false && slug !== cot2.slug) {
                                                        //console.log("recursive isSlugShouldBeHidden2 ", slug, cot2.slug);
                                                        result = this.isSlugShouldBeHidden2(cot2.slug, purchaseDetail, clothingDetails);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            

                            // if (result === false) {
                            //     // we can check the clothingOptionValues
                            //     for (const cov of co.clothingOptionValues) {
                            //         if (result === false) {
                            //             cov.rules?.displayConditions?.find((dc) => dc.)
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
        }
        //console.log('isSlugShouldBeHidden2 result', slug, result);
        return result;
    },
    getClothingOptionValueFromSlug(slug: string, clothingDetails: IClothingDetails): undefined | IClothingOptionValue {
        let result = undefined as undefined | IClothingOptionValue;
        for (const cot of clothingDetails.clothingOptionTypes) {
            if (result === undefined) {
                for (const co of cot.clothingOptions) {
                    if (result === undefined) {
                        for (const cov of co.clothingOptionValues) {
                            if (result === undefined) {
                                if (cov.slug === slug) {
                                    result = cov;
                                }
                            }
                        }
                    }       
                }
            }
        }

        return result;
    },
    getSlugFromClothingId(clothingId: number, clothingDetails: IClothingDetails): string {
        let result = "";
        for (const cot of clothingDetails.clothingOptionTypes) {
            if (result === "") {
                for (const co of cot.clothingOptions) {
                    if (result === "") {
                        if (co.id === clothingId) {
                            result = co.slug;
                        }
                    }       
                }
            }
        }

        return result;
    },
    getClothingIdFromSlug(slug: string, clothingDetails: IClothingDetails): number {
        let result = 0;
        for (const cot of clothingDetails.clothingOptionTypes) {
            if (result === 0) {
                for (const co of cot.clothingOptions) {
                    if (result === 0) {
                        if (co.slug === slug) {
                            result = co.id;
                        } else {
                            for (const cov of co.clothingOptionValues) {
                                if (result === 0) {
                                    if (cov.slug === slug) {
                                        result = cov.id;
                                    }
                                }
                            }
                        }
                    }       
                }
            }
        }

        return result;
    },
    getClothingIdFromSlugForRadio(slug: string, clothingDetails: IClothingDetails): number {
        let result = 0;
        for (const cot of clothingDetails.clothingOptionTypes) {
            if (result === 0) {
                for (const co of cot.clothingOptions) {
                    if (result === 0) {
                        if (co.slug === slug) {
                            result = co.id;
                        } else {
                            for (const cov of co.clothingOptionValues) {
                                if (result === 0) {
                                    if (cov.slug === slug) {
                                        result = co.id;
                                    }
                                }
                            }
                        }
                    }       
                }
            }
        }

        return result;
    },
    getClothingValueFromSlug(slug: string, clothingDetails: IClothingDetails): IClothingOptionValue | undefined{
        let result = undefined;
        for (const cot of clothingDetails.clothingOptionTypes) {
            if (result === undefined) {
                for (const co of cot.clothingOptions) {
                    if (result === undefined) {
                        for (const cov of co.clothingOptionValues) {
                            if (result === undefined) {
                                if (cov.slug === slug) {
                                    result = cov;
                                }
                            }
                        }
                    }       
                }
            }
        }
        return result;
    },
    getClothingOptionIDFromClothingOptionValueSlug(slug: string, clothingDetails: IClothingDetails): number{
        let result = 0;
        for (const cot of clothingDetails.clothingOptionTypes) {
            if (result === 0) {
                for (const co of cot.clothingOptions) {
                    if (result === 0) {
                        for (const cov of co.clothingOptionValues) {
                            if (result === 0) {
                                if (cov.slug === slug) {
                                    result = co.id;
                                }
                            }
                        }
                    }       
                }
            }
        }
        return result;
    },
    getPurchaseStatus(purchase: IPurchase): IPurchaseToPurchaseStatus {
        const _activePurchase = purchase as IPurchase;
        let _date = "";
        let result : IPurchaseToPurchaseStatus = {} as IPurchaseToPurchaseStatus;

        for (const status of _activePurchase.purchaseToPurchaseStatuses) {
            if (_date < status.updatedAt) {
                _date = status.updatedAt;
                result = status;
            }
        }

        return result;
    },
    formatDate(payload: string): string {
        let result = "";
        if (payload && payload !== "" && payload.length >= 10) {
            result = payload.substring(8,10) + "/" + payload.substring(5,7) + "/" + payload.substring(0,4);
        }
        return result;
    },
    isFabricField(metadata: IClothingContentRulesMetadata): IFabricFieldInfo {
        const result = {
            isFabricField: false,
            typeStock: StockType.NON_CONCERNE
        
        } as IFabricFieldInfo;

        if (metadata.limitedStock && metadata.limitedStock !== "") {
            result.isFabricField = true;
            result.typeStock = metadata.limitedStock as StockType;
        }
        return result;    
    },
    getStock(value: string, typeStock: StockType): IStock | undefined {
        const stocks = store.getters["stock/stocks"] as IStock[];
        if (stocks) {
            return stocks.find(s => s.reference.toUpperCase() === value.toUpperCase() && s.type === typeStock);
        }
        return undefined;
    },
    getDefaultClothingId(): number {
        const clothings = store.getters["clothing/clothings"] as IClothing[];
        const c = clothings.find(cl => cl.slug = ClothingSlug.COSTUME_2_PIECES);
        if (c) {
            return c.id;
        }
        else {
            return 0;
        }
    },
    getDefaultMeasurementTypeId(): number {
        const measurementRef = store.getters["measurement/measurementRef"] as IClothingType[]
        const measurement = measurementRef.find(m => m.orderMeasure === 1);
        console.log("getDefaultMeasurementTypeId =>", measurement, measurementRef);
        if (measurement) {
            return measurement.id;
        } else {
            return 1;
        }
        
    },
    getMeasurementMorphologyId(): number {
        const measurementDetails = store.getters["measurement/measurementDetails"] as IMeasurementType[];
        const m = measurementDetails.find(md => md.slug === MeasurementSlug.MORPHOLOGY);
        if (m) {
            return m.id;
        } else {
            return 1;
        }
    },
    getClothingDetailsFromClothingOptionTypeId(clothingOptionTypeId: number): IClothingDetails | undefined {
        const _clothingDetails = store.getters["clothing/clothingDetails"] as IClothingDetails[];
        let result;

        for (const cd of _clothingDetails) {
            if (result === undefined && cd.assembly && cd.assembly.length === 1) {
                const findResult = cd.clothingOptionTypes.find(cot => cot.id === clothingOptionTypeId);
                if (findResult) {
                    result = cd;
                }
            }
        }

        return result;
    },
    isTouchNeeded() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|safari|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    },
    getApiUrl(): string {
        let apiUrl = constantes.API_URL;

        console.log("process.env", process.env);

        if (process.env && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage')) {
            apiUrl = constantes.API_URL;
        } else {
            apiUrl = constantes.API_URL_PROD;
        }

        // if (process.env && process.env.URL_API) {
        //     apiUrl = process.env.URL_API;
        // }

        return apiUrl;
    }
}