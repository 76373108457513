import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { StockActionTypes } from "./action-types";
import { StockMutationTypes } from "./mutation-types";
import stockService from "@/services/stock.service";
import { IStock } from "@/types/IStock";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [StockActionTypes.LOAD_STOCKS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [StockActionTypes.RESET_STOCKS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [StockActionTypes.UPDATE_STOCKS](augmentedActionContext: AugmentedActionContext, payload: IStock[]): Promise<void>;
    [StockActionTypes.SET_IS_UPLOAD_DONE](augmentedActionContext: AugmentedActionContext, payload: boolean): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [StockActionTypes.LOAD_STOCKS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      await loadStocks(augmentedActionContext);
    },
    async [StockActionTypes.RESET_STOCKS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      augmentedActionContext.commit(StockMutationTypes.LOAD_STOCKS, [] as IStock[]);
      //await loadStocks(augmentedActionContext);
    },
    async [StockActionTypes.UPDATE_STOCKS](augmentedActionContext: AugmentedActionContext, payload: IStock[]): Promise<void> {
        augmentedActionContext.commit(StockMutationTypes.SET_IS_UPLOAD_DONE, false);
        await stockService.updateStock(payload);
        await loadStocks(augmentedActionContext);
        augmentedActionContext.commit(StockMutationTypes.SET_IS_UPLOAD_DONE, true);
    },
    [StockActionTypes.SET_IS_UPLOAD_DONE](augmentedActionContext: AugmentedActionContext, payload: boolean) {
      augmentedActionContext.commit(StockMutationTypes.SET_IS_UPLOAD_DONE, payload);
    }
}

async function loadStocks(augmentedActionContext: AugmentedActionContext): Promise<void> {
  const stocks = await stockService.loadStocks();
  //console.log("stock", stocks);
  const stocksFiltered = [] as IStock[];
  // const stocksFiltered = stocks.filter(s => 
  //   parseInt(s.quantity) > 0 && 
  //   (s.arrival === null || (new Date(s.arrival)).valueOf() <= (new Date()).valueOf()) &&
  //   (s.endOfLife === null || (new Date()).valueOf() <= (new Date(s.endOfLife)).valueOf())
  // );

  for (const s of stocks) {
    // test quantity
    if (parseInt(s.quantity) > 0) {
      // test arrival date
      let isArrivalDateOk = false;
      if (s.arrival === null) {
        isArrivalDateOk = true;
      } else {
        let d = s.arrival.split("/");
        let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        //console.log(s, dat);
        if (dat.valueOf() <= (new Date()).valueOf()) {
          isArrivalDateOk = true;
        }
      }

      if (isArrivalDateOk) {
        // test endOfLife date
        let isEndDateOk = false;
        if (s.endOfLife === null) {
          isEndDateOk = true;
        } else {
          let d2 = s.endOfLife.split("/");
          let dat2 = new Date(d2[2] + '/' + d2[1] + '/' + d2[0]);
          //console.log(s, dat2);
          if (dat2.valueOf() >= (new Date()).valueOf()) {
            isEndDateOk = true;
          }
        }

        if (isEndDateOk) {
          stocksFiltered.push(s);
        }
      }
    }
  }

  //console.log("stocksFiltered", stocksFiltered);
  augmentedActionContext.commit(StockMutationTypes.LOAD_STOCKS, stocksFiltered);
}