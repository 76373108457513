import { GetterTree } from "vuex";
import { ICustomer, ICustomerReferential } from "@/types/ICustomer.interface";
import { RootState } from "@/store";
import { State } from "./state";
import { IFilterAndSearchPurchase } from "@/types/IPurchase.interface";

export type Getters = {
    //customers(state: State): ICustomer[];
    activeCustomer(state: State): ICustomer | undefined;
    referentialHeight(state: State): ICustomerReferential[];
    referentialWeight(state: State): ICustomerReferential[];
    referentialShoesSize(state: State): ICustomerReferential[];
    referentialOrigin(state: State): ICustomerReferential[];
    referentialMotivation(state: State): ICustomerReferential[];
    searchCustomer(state: State): IFilterAndSearchPurchase;
    nbSearch(state: State): number;
}

export const getters: GetterTree<State, RootState> & Getters = {
    // customers: (state) => {
    //     if (state.customers) {
    //         return state.customers;
    //     } else {
    //         return [];
    //     }
    // },
    activeCustomer: (state) => state.activeCustomer,
    referentialHeight: (state) => state.referentialHeight,
    referentialWeight: (state) => state.referentialWeight,
    referentialShoesSize: (state) => state.referentialShoesSize,
    referentialOrigin: (state) => state.referentialOrigin,
    referentialMotivation: (state) => state.referentialMotivation,
    searchCustomer: (state) => state.searchCustomer,
    nbSearch: (state: State) => state.searchCustomer.nbSearch,
}