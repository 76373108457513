import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { ILoadingRessourceInformation, INavigation } from "@/types/INavigation.interface";

export type Getters = {
    navigationHistoryForLog(state: State): INavigation[];
    navigationHistoryForDisplay(state: State): INavigation[];
    isModifGoingOn(state: State): boolean;
    downLevelsForModif(state: State): number;
    loadingInProgress(state: State): boolean;
    loadingRessources(state: State): ILoadingRessourceInformation[];
    homeTabSelected(state: State): string;
}

export const getters: GetterTree<State, RootState> & Getters = {
    navigationHistoryForLog: (state) => state.navigationHistoryForLog,
    navigationHistoryForDisplay: (state) => state.navigationHistoryForDisplay,
    isModifGoingOn: (state) => state.isModifGoingOn,
    downLevelsForModif: (state) => state.downLevelsForModif,
    loadingInProgress: (state) => state.loadingInProgress,
    loadingRessources: (state) => state.loadingRessources,
    homeTabSelected: (state) => state.homeTabSelected,
}