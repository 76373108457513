import { MutationTree } from "vuex";

import { State } from "./state";
import { MeasurementMutationTypes as MutationTypes } from "./mutation-types";
import { IClothing, IClothingType } from "@/types/IClothing.interface";
import { IMeasurementType, ITemplate, ITemplateType } from "@/types/IMeasurement.interface";

export type Mutations<S = State> = {
    [MutationTypes.SET_MEASUREMENT_REF](state: S, payload: IClothingType[]): void;
    [MutationTypes.ADD_MEASUREMENT_DETAILS](state: S, payload: IMeasurementType): void;
    [MutationTypes.SET_ACTIVE_MEASUREMENT_TYPE](state: S, payload: IMeasurementType | undefined): void;
    [MutationTypes.ADD_TEMPLATE](state: S, payload: ITemplateType): void;
    [MutationTypes.RESET_MEASUREMENT](state: S): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_MEASUREMENT_REF](state: State, ref: IClothingType[]) {
        state.measurementRef = ref;
    },
    [MutationTypes.ADD_MEASUREMENT_DETAILS](state: State, details: IMeasurementType) {
        state.measurementDetails.push(details);
        state.measurementDetails = [...state.measurementDetails];
    },
    [MutationTypes.SET_ACTIVE_MEASUREMENT_TYPE](state: State, details: IMeasurementType | undefined) {
        state.activeMeasurementType = undefined;
        state.activeMeasurementType = details;
    },
    [MutationTypes.ADD_TEMPLATE](state: State, template: ITemplateType) {
        state.templates.push(template);
    },
    [MutationTypes.RESET_MEASUREMENT](state: State) {
        state.measurementRef = [] as IClothingType[];
        state.measurementDetails = [] as IMeasurementType[];
        state.templates = [] as ITemplateType[];
        state.activeMeasurementType = undefined;
    }
}