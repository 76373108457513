import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { state, State } from "./state";
import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase, IPurchaseDetail } from "@/types/IPurchase.interface";

export type Getters = {
  activePurchase(state: State): IPurchase;
  activePurchaseDetail(state: State): IPurchaseDetail;
  //purchases(state: State): IPurchase[];
  displayImages(state: State): boolean;
  isFilterActive(state: State): boolean;
  purchaseFilters(state: State): IFilterPurchase[];
  actionInProgress(state: State): boolean;
  statusPurchaseUpdated(state: State): number;
  nbFilterSelected(state: State): number;
  filterAndSearchPurchase(state: State): IFilterAndSearchPurchase;
  nbSearch(state: State): number;
};

export const getters: GetterTree<State, RootState> & Getters = {
  activePurchase: (state) => state.activePurchase,
  activePurchaseDetail: (state) => state.activePurchaseDetail,
  //purchases: (state) => state.purchases,
  displayImages: (state) => state.displayImages,
  isFilterActive: (state: State) => state.isFilterActive,
  purchaseFilters: (state: State) => state.purchaseFilters,
  actionInProgress: (state: State) => state.actionInProgress,
  statusPurchaseUpdated: (state: State) => state.statusPurchaseUpdated,
  nbFilterSelected: (state: State) => {
    if (state.purchaseFilters) {
      const filtersSelected = state.purchaseFilters.filter(pf => pf.isSelected);
      return filtersSelected.length;
    }
    return 0;
  },
  filterAndSearchPurchase: (state: State) => state.filterAndSearchPurchase,
  nbSearch: (state: State) => state.filterAndSearchPurchase.nbSearch,
};
