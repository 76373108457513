import { IErrorCustom } from "@/types/IErrorCustom";
import { IErrorFromService } from "@/types/IErrorFromService.interface";
import { MutationTree } from "vuex";

import { ErrorCustomMutationTypes as MutationTypes } from "./mutation-types";
import { State } from "./state";


export type Mutations<S = State> = {
    [MutationTypes.ADD_ERROR](state: S, payload: IErrorCustom): void;
    [MutationTypes.CLEAR_ERRORS](state: S): void;
    [MutationTypes.NEW_ERROR](state: S, payload: IErrorFromService): void;
    [MutationTypes.DISPLAY_ERROR](state: S): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.ADD_ERROR](state: State, error: IErrorCustom) {
        error.id = state.errors.length + 1;
        state.errors.push(error);
        state.errors = [...state.errors];
    },
    [MutationTypes.NEW_ERROR](state: State, payload: IErrorFromService) {
        state.errorsFromService.push(payload);
        state.isErrorToDisplay = true;
    },
    [MutationTypes.DISPLAY_ERROR](state: State) {
        state.isErrorToDisplay = false;
    },
    [MutationTypes.CLEAR_ERRORS](state: State) {
        state.errors = [] as IErrorCustom[];
        state.errorsFromService = [] as IErrorFromService[];
    }
}