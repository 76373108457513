export enum NavigationMutationTypes {
    NAVIGATE_FORWARD = "NAVIGATE_FORWARD",
    NAVIGATE_BACKWARD = "NAVIGATE_BACKWARD",
    NAVIGATE_TO_PREVIOUS = "NAVIGATE_TO_PREVIOUS",
    RESET_NAVIGATION = "RESET_NAVIGATION",
    CHANGE_MODIFY_STATUS = "CHANGE_MODIFY_STATUS",
    SET_IS_MODIF_GOING_ON = "SET_IS_MODIF_GOING_ON",
    NAVIGATE_FROM_THE_BEGINNING = "NAVIGATE_FROM_THE_BEGINNING",
    SET_LOADING_IN_PROGRESS = "SET_LOADING_IN_PROGRESS",
    CLEAR_LOADING_IN_PROGRESS = "CLEAR_LOADING_IN_PROGRESS",
    SET_HOME_TAB_SELECTED = "SET_HOME_TAB_SELECTED",
}