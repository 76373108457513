import { MutationTree } from "vuex";

import { State } from "./state";
import { ColorMutationTypes as MutationTypes } from "./mutation-types";
import { IColor } from "@/types/IColor";

export type Mutations<S = State> = {
    [MutationTypes.LOAD_COLORS](state: S, payload: IColor[]): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.LOAD_COLORS](state: State, payload: IColor[]) {
        state.colors = payload;
    },
}