import LoginView from '@/views/LoginView.vue'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { RouterEnum } from './router.enum'

const routes: Array<RouteRecordRaw> = [
  {
    path: RouterEnum.LOGIN_PATH,
    name: RouterEnum.LOGIN_NAME,
    component: LoginView
  },
  {
    path: RouterEnum.HOME_PATH,
    name: RouterEnum.HOME_NAME,
    component: () => import ('@/views/Home.vue')
  },
  {
    path: RouterEnum.MEASUREMENT_PATH,
    name: RouterEnum.MEASUREMENT_NAME,
    component: () => import ('../components/measurement/CustomerMeasurement.vue')
  },
  {
    path: RouterEnum.CUSTOMER_INFOS_PATH,
    name: RouterEnum.CUSTOMER_INFOS_NAME,
    component: () => import ('../components/customer/CustomerInfos.vue'),
  },
  {
    path: RouterEnum.CUSTOMER_PURCHASE_PATH,
    name: RouterEnum.CUSTOMER_PURCHASE_NAME,
    component: () => import ('../components/purchase/CustomerPurchase.vue'),
  },
  {
    path: RouterEnum.PURCHASE_DETAILS_PATH,
    name: RouterEnum.PURCHASE_DETAILS_NAME,
    component: () => import ('../components/purchase/PurchaseDetails.vue'),
  },
  {
    path: RouterEnum.PURCHASE_ADJUSTMENT_PATH,
    name: RouterEnum.PURCHASE_ADJUSTMENT_NAME,
    component: () => import ('../components/adjustment/PurchaseAdjustment.vue'),
  },
  {
    path: RouterEnum.PURCHASE_ADJUSTMENT_RECAP_PATH,
    name: RouterEnum.PURCHASE_ADJUSTMENT_RECAP_NAME,
    component: () => import ('../components/adjustment/PurchaseAdjustmentRecap.vue'),
  },
  {
    path: RouterEnum.PURCHASE_RECAP_PATH,
    name: RouterEnum.PURCHASE_RECAP_NAME,
    component: () => import ('../components/purchase/PurchaseRecap.vue'),
  },
  {
    path: RouterEnum.PDF_EXPORT_ADJUSTMENT_PATH,
    name: RouterEnum.PDF_EXPORT_ADJUSTMENT_NAME,
    component: () => import ('../components/pdf/ExportAdjustment.vue'),
  },
  {
    path: RouterEnum.PDF_EXPORT_PURCHASE_PATH,
    name: RouterEnum.PDF_EXPORT_PURCHASE_NAME,
    component: () => import ('../components/pdf/ExportPurchase.vue'),
  },
  {
    path: RouterEnum.RANDOM_PAGE_PATH,
    name: RouterEnum.RANDOM_PAGE_NAME,
    component: () => import ('../components/databaseInspector/DatabaseInspector.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// // after createRouter
// let programmatic = false;
// (['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
//   // const method = router[methodName]
//   // router[methodName] = (...args) => {
//   //   programmatic = true
//   //   method.apply(router, args)
//   // }
//   console.log("router test => ", methodName);
// })

// router.beforeEach(async (to, from) => {
//   if(!from.name === null || !programmatic) {
//     // do stuff you want to do when hitting back/forward or reloading page
//   }
//   programmatic = false // clear flag
// });

export default router
