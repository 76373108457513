import { IClothing, IClothingType } from "@/types/IClothing.interface"
import { IMeasurementType, ITemplateType } from "@/types/IMeasurement.interface"

export type State = {
    measurementRef: IClothingType[],
    measurementDetails: IMeasurementType[],
    activeMeasurementType: IMeasurementType | undefined,
    templates: ITemplateType[]; 
}

export const state: State = {
    measurementRef: [],
    measurementDetails: [],
    activeMeasurementType: undefined,
    templates: []
}