
import { IErrorFromService } from "@/types/IErrorFromService.interface";
import { json } from "stream/consumers";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export interface DisplayNavigation {
    date: string;
    label: string;
    nbTab: number;
}

export default defineComponent({
  
    name: "ErrorRecap",
    data() {
        return {
            nbTab: 0,
            display: false,
        };
    },
    computed: {
        ...mapGetters('errorCustom', ["errorsFromService"]),
        history(): IErrorFromService[] {
            if (this.errorsFromService) {
                const tab = this.errorsFromService as IErrorFromService[];
                return tab;
            } else {
                return [] as IErrorFromService[];
            }
        },
    },
    methods: {
        displayErreur(error: IErrorFromService) {
            console.log(error);
        }
    }
})
