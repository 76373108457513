import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { NavigationMutationTypes as MutationTypes } from "./mutation-types";
import { NavigationActionTypes as ActionTypes } from "./action-types";
import { ILoadingRessourceInformation, INavigation } from "@/types/INavigation.interface";
import router from '@/router';

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [ActionTypes.NAVIGATE_BACKWARD](augmentedActionContext: AugmentedActionContext, payload: INavigation): void;
    [ActionTypes.NAVIGATE_FORWARD](augmentedActionContext: AugmentedActionContext, payload: INavigation): void;
    [ActionTypes.NAVIGATE_TO_PREVIOUS](augmentedActionContext: AugmentedActionContext): void;
    [ActionTypes.NAVIGATE_FORWARD_FROM_THE_BEGINNING](augmentedActionContext: AugmentedActionContext, payload: INavigation): void;
    [ActionTypes.SET_NAVIGATION_TO_PREVIOUS](augmentedActionContext: AugmentedActionContext): void;
    [ActionTypes.CHANGE_MODIFY_STATUS](augmentedActionContext: AugmentedActionContext, payload: boolean): void;
    [ActionTypes.SET_IS_MODIF_GOING_ON](augmentedActionContext: AugmentedActionContext, payload: boolean): void;
    [ActionTypes.SET_LOADING_IN_PROGRESS](augmentedActionContext: AugmentedActionContext, payload: ILoadingRessourceInformation): void;
    [ActionTypes.CLEAR_LOADING_IN_PROGRESS](augmentedActionContext: AugmentedActionContext): void;

    [ActionTypes.RESET_NAVIGATION](augmentedActionContext: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.NAVIGATE_BACKWARD](augmentedActionContext: AugmentedActionContext, payload: INavigation): void {
        augmentedActionContext.commit(MutationTypes.NAVIGATE_BACKWARD, payload);
        router.push(payload.path);
    },
    [ActionTypes.NAVIGATE_FORWARD](augmentedActionContext: AugmentedActionContext, payload: INavigation): void {
        augmentedActionContext.commit(MutationTypes.NAVIGATE_FORWARD, payload);
        router.push(payload.path);
    },
    // ajouter un navigate_backward_one qui fait juste un router.back et supprime la derniere ligne de historyForDisplay
    [ActionTypes.NAVIGATE_TO_PREVIOUS](augmentedActionContext: AugmentedActionContext): void {
        // no need anymore because the .back is the same as button previous from browser
        //augmentedActionContext.commit(MutationTypes.NAVIGATE_TO_PREVIOUS, undefined);
        router.back();
    },
    [ActionTypes.NAVIGATE_FORWARD_FROM_THE_BEGINNING](augmentedActionContext: AugmentedActionContext, payload: INavigation): void {
        augmentedActionContext.commit(MutationTypes.NAVIGATE_FROM_THE_BEGINNING, undefined);
        augmentedActionContext.commit(MutationTypes.NAVIGATE_FORWARD, payload);
        router.push(payload.path);
    },
    [ActionTypes.SET_NAVIGATION_TO_PREVIOUS](augmentedActionContext: AugmentedActionContext): void {
        // to deal with the back button from browser
        augmentedActionContext.commit(MutationTypes.NAVIGATE_TO_PREVIOUS, undefined);
    },
    [ActionTypes.CHANGE_MODIFY_STATUS](augmentedActionContext: AugmentedActionContext, payload: boolean): void {
        augmentedActionContext.commit(MutationTypes.CHANGE_MODIFY_STATUS, payload);
    },
    [ActionTypes.SET_IS_MODIF_GOING_ON](augmentedActionContext: AugmentedActionContext, payload: boolean): void {
        augmentedActionContext.commit(MutationTypes.SET_IS_MODIF_GOING_ON, payload);
    },
    [ActionTypes.SET_LOADING_IN_PROGRESS](augmentedActionContext: AugmentedActionContext, payload: ILoadingRessourceInformation): void {
        //augmentedActionContext.commit(MutationTypes.SET_LOADING_IN_PROGRESS, payload);
    },
    [ActionTypes.CLEAR_LOADING_IN_PROGRESS](augmentedActionContext: AugmentedActionContext): void {
        augmentedActionContext.commit(MutationTypes.CLEAR_LOADING_IN_PROGRESS, undefined);
    },
    [ActionTypes.SET_HOME_TAB_SELECTED](augmentedActionContext: AugmentedActionContext, payload: string): void {
        augmentedActionContext.commit(MutationTypes.SET_HOME_TAB_SELECTED, payload);
    },

    [ActionTypes.RESET_NAVIGATION](augmentedActionContext: AugmentedActionContext): void {
        augmentedActionContext.commit(MutationTypes.RESET_NAVIGATION, undefined);
    }

}
