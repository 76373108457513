export enum AdminActionTypes {
    SET_ADMIN_MENU_ACTIF = "SET_ADMIN_MENU_ACTIF",
    LOAD_SHOPS = "LOAD_SHOPS",
    CREATE_SHOP = "CREATE_SHOP",
    UPDATE_SHOP = "UPDATE_SHOP",
    RESET_ADMIN = "RESET_ADMIN",
    GET_BACKEND_VERSION = "GET_BACKEND_VERSION",

    ADMIN_SET_ADMIN_MENU_ACTIF = "admin/SET_ADMIN_MENU_ACTIF",
    ADMIN_LOAD_SHOPS = "admin/LOAD_SHOPS",
    ADMIN_CREATE_SHOP = "admin/CREATE_SHOP",
    ADMIN_UPDATE_SHOP = "admin/UPDATE_SHOP",
    ADMIN_RESET_ADMIN = "admin/RESET_ADMIN",
    ADMIN_GET_BACKEND_VERSION = "admin/GET_BACKEND_VERSION",
}