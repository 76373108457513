export enum CustomerMutationTypes {
    SET_ACTIVE_CUSTOMER = "SET_ACTIVE_CUSTOMER",
    UPDATE_MEASUREMENT = "UPDATE_MEASUREMENT",
    SET_REFERENTIAL_HEIGHT = "SET_REFERENTIAL_HEIGHT",
    SET_REFERENTIAL_WEIGHT = "SET_REFERENTIAL_WEIGHT",
    SET_REFERENTIAL_SHOES_SIZE = "SET_REFERENTIAL_SHOES_SIZE",
    SET_REFERENTIAL_ORIGIN = "SET_REFERENTIAL_ORIGIN",
    SET_REFERENTIAL_MOTIVATION = "SET_REFERENTIAL_MOTIVATION",
    SET_SEARCH_CUSTOMER = 'SET_SEARCH_CUSTOMER',
    RESET_CUSTOMER = "RESET_CUSTOMER",

}