import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { state, State } from "./state";
import { Mutations } from './mutations';
import { Getters } from "./getters";
import { MeasurementMutationTypes } from "./mutation-types";
import { MeasurementActionTypes } from "./action-types";

import clothingService from "@/services/clothing.service";
import { IClothing, IClothingDetails } from "@/types/IClothing.interface";
import { IIhmOrder } from "@/types/IIhmOrder.interface";
import { IUser } from "@/types/IUser.interface";
import { ICustomer } from "@/types/ICustomer.interface";
import measurementService from "@/services/measurement.service";
import { IMeasurementType } from "@/types/IMeasurement.interface";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>
      };
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [MeasurementActionTypes.LOAD_MEASUREMENT_REF](augmentedActionContext: AugmentedActionContext, payload: string): void;
    [MeasurementActionTypes.SET_ACTIVE_MEASUREMENT_TYPE]({ commit, getters }: AugmentedActionContext, payload: number): void;
    [MeasurementActionTypes.RESET_MEASUREMENT](augmentedActionContext: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [MeasurementActionTypes.LOAD_MEASUREMENT_REF](augmentedActionContext: AugmentedActionContext): Promise<void> {
        await loadMeasurement(augmentedActionContext);
    },
    async [MeasurementActionTypes.SET_ACTIVE_MEASUREMENT_TYPE]({commit, getters}, measurementId: number): Promise<void> {
        const details = getters.measurementDetails as IMeasurementType[];
        const measurementDetails = details.find(m => {
            if (m.id === measurementId) {
                return true;
            }
        });
        commit(MeasurementMutationTypes.SET_ACTIVE_MEASUREMENT_TYPE, measurementDetails);
    },
    async [MeasurementActionTypes.RESET_MEASUREMENT](augmentedActionContext: AugmentedActionContext): Promise<void> {
        augmentedActionContext.commit(MeasurementMutationTypes.RESET_MEASUREMENT, undefined);
        //await loadMeasurement(augmentedActionContext);
    }
}

async function loadMeasurement(augmentedActionContext: AugmentedActionContext): Promise<void> {
    const ref = augmentedActionContext.getters.measurementRef as IClothing[];

        if (ref && ref.length > 0) {
        } else {
            const measurements = await measurementService.getMeasurementTypes();
            augmentedActionContext.commit(MeasurementMutationTypes.SET_MEASUREMENT_REF, measurements);
            
            for (const m of measurements) {
                try {
                    // load referentiel measurement
                    const details = await measurementService.getMeasurements(m.id);
                    augmentedActionContext.commit(MeasurementMutationTypes.ADD_MEASUREMENT_DETAILS, details);

                    // load referentiel templates
                    const template = await measurementService.getTemplate(m.id);
                    //console.log(m, template);
                    augmentedActionContext.commit(MeasurementMutationTypes.ADD_TEMPLATE, template);
                    
                } catch (error) {
                    console.log(error);
                }
            }
        }
}