export interface IStock {
    id: number;
    createdAt: string;
    updatedAt: string;
    reference: string;
    quantity: string;
    arrival: string;
    endOfLife: string;
    type: StockType;
    error: string;
    unit: string | null;
    details: string | null;
}

export enum StockType {
    TISSU = "tissu",
    DOUBLURE = "doublure",
    SATIN = "satin",
    FEUTRE = "feutre",
    BOUTON = "bouton",
    NON_CONCERNE = "non_concerne",
}


export interface IFabricFieldInfo {
    isFabricField: boolean,
    typeStock: StockType
}