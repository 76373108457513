import constantes from "@/constantes/constantes";
import { IColor } from "@/types/IColor";
import toolsService from "./tools.service";

const apiUrlPurchase = toolsService.getApiUrl() + "v1/color";

export default {
    async loadColors(): Promise<IColor[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(apiUrlPurchase, requestOptions, {}, "color.service.ts", "loadColors");
        } else {
            return new Promise(resolve => resolve([] as IColor[]));
        }
    },
}