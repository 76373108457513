import { MutationTree } from "vuex";

import { State } from "./state";
import { AdjustmentMutationTypes as MutationTypes } from "./mutation-types";
import { IAdjustment, IAdjustmentCommentsPayload } from "@/types/IAdjustment.interface";
import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase } from "@/types/IPurchase.interface";

export type Mutations<S = State> = {
    [MutationTypes.SET_ACTIVE_ADJUSTMENT](state: S, payload: IAdjustment): void;
    [MutationTypes.SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID](state: S, payload: number): void;
    //[MutationTypes.LOAD_ADJUSTMENTS](state: S, payload: IAdjustment[]): void;
    //[MutationTypes.SET_FILTERED_PURCHASES_ADJUSTMENT](state: S, payload: IPurchase[]): void;
    [MutationTypes.INIT_PURCHASE_ADJUSTMENT_FILTERS](state: S, payload: IFilterPurchase[]): void;
    [MutationTypes.SET_FILTER_ACTIVE](state: S, payload: boolean): void;
    [MutationTypes.LOAD_ADJUSTMENTS_FROM_PURCHASE_ID](state: S, payload: IAdjustment): void;
    [MutationTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT](state: S, payload: IFilterAndSearchPurchase): void;
    [MutationTypes.UPDATE_ADJUSTMENT_COMMENTS](state: S, payload: IAdjustmentCommentsPayload): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_ACTIVE_ADJUSTMENT](state: State, adjustment: IAdjustment) {
        console.log("adjustment mutation", adjustment);
        state.activeAdjustment = adjustment;
    },
    [MutationTypes.SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID](state: State, payload: number) {
        state.activeAdjustment.customerId = payload;
    },
    // [MutationTypes.LOAD_ADJUSTMENTS](state: State, payload: IAdjustment[]) {
    //     state.adjustments = payload;
    // },
    // [MutationTypes.SET_FILTERED_PURCHASES_ADJUSTMENT](state: State, payload: IPurchase[]) {
    //     state.filteredPurchasesAdjustment = payload;
    // },
    [MutationTypes.INIT_PURCHASE_ADJUSTMENT_FILTERS](state: State, payload: IFilterPurchase[]) {
        state.purchaseAdjustmentFilters = [...payload];
    },
    [MutationTypes.SET_FILTER_ACTIVE](state: State, payload: boolean) {
        state.isFilterActive = payload;
    },
    [MutationTypes.LOAD_ADJUSTMENTS_FROM_PURCHASE_ID](state: State, payload: IAdjustment) {
        //console.log("MutationTypes.LOAD_ADJUSTMENTS_FROM_PURCHASE_ID", payload.id, payload);
        state.adjustmentFromPurchaseId = payload;
    },
    [MutationTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT](state: State, payload: IFilterAndSearchPurchase) {
        state.filterAndSearchAdjustment = payload;
    },
    [MutationTypes.UPDATE_ADJUSTMENT_COMMENTS](state: State, payload: IAdjustmentCommentsPayload) {
        if (state.activeAdjustment && state.activeAdjustment.id === payload.adjustmentId) {
            state.activeAdjustment.comments = payload.comments;
        }
    }
}