import { IShop } from "@/types/IShop.interface";
import toolsService from "./tools.service";
import constantes from "@/constantes/constantes";
import { IApp } from "@/types/IApp.interface";

export default {
    async getShops(): Promise<IShop[]> {
        //if (toolsService.isAdminConnected() || toolsService.isChainSupplyConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/shop", requestOptions, {}, "admin.service.ts", "getShops"); 
        // } else {
        //     return new Promise(resolve => resolve([] as IShop[]));
        // }
    },
    async createShop(shop: IShop) {
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(shop));
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/shop", requestOptions, shop,  "admin.service.ts", "createShop");
    },
    async updateShop(shop: IShop) {
        const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(shop));
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/shop/" + shop.id, requestOptions, shop, "admin.service.ts", "updateShop");
    },
    async getBackendVersion(): Promise<IApp> {
        const requestOptions = toolsService.getRequestOption("GET", "");
        return toolsService.fetchRessource(toolsService.getApiUrl() + "version", requestOptions, {}, "admin.service.ts", "getBackendVersion"); 
    }
}