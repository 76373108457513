import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate';

import { store as user, UserStore, State as UserState } from '@/store/modules/user';
import { store as customer, CustomerStore, State as CustomerState } from '@/store/modules/customer';
import { store as admin, AdminStore, State as AdminState } from '@/store/modules/admin';
import { store as clothing, ClothingStore, State as ClothingState } from '@/store/modules/clothing';
import { store as measurement, MeasurementStore, State as MeasurementState} from '@/store/modules/measurement';
import { store as errorCustom, ErrorCustomStore, State as ErrorCustomState} from '@/store/modules/errorCustom';
import { store as purchase, PurchaseStore, State as PurchaseState} from '@/store/modules/purchase';
import { store as adjustment, AdjustmentStore, State as AdjustmentState} from '@/store/modules/adjustment';
import { store as navigation, NavigationStore, State as NavigationState} from '@/store/modules/navigation';
import { store as stock, StockStore, State as StockState} from '@/store/modules/stock'; 
import { store as color, ColorStore, State as ColorState} from '@/store/modules/color';

export type RootState = {
  user: UserState,
  customer: CustomerState,
  admin: AdminState,
  clothing: ClothingState,
  measurement: MeasurementState,
  errorCustom: ErrorCustomState,
  purchase: PurchaseState,
  adjustment: AdjustmentState,
  navigation: NavigationState,
  stock: StockState,
  color: ColorState
}

export type Store = UserStore<Pick<RootState, 'user'>>
  & CustomerStore<Pick<RootState, 'customer'>>
  & AdminStore<Pick<RootState, 'admin'>>
  & ClothingStore<Pick<RootState, 'clothing'>>
  & MeasurementStore<Pick<RootState, 'measurement'>>
  & ErrorCustomStore<Pick<RootState, 'errorCustom'>>
  & PurchaseStore<Pick<RootState, 'purchase'>>
  & AdjustmentStore<Pick<RootState, 'adjustment'>>
  & NavigationStore<Pick<RootState, 'navigation'>>
  & StockStore<Pick<RootState, 'stock'>>
  & ColorStore<Pick<RootState, 'color'>>
  ;

const plugins = [];
plugins.push(createPersistedState({ storage: window.localStorage}));

export const store = createStore({
  plugins,
  modules: {
    user,
    customer,
    admin,
    clothing,
    measurement,
    errorCustom,
    purchase,
    adjustment,
    navigation,
    stock,
    color,
  }
})

export function useStore(): Store {
  return store as Store;
}