export enum CustomerActionTypes {
    GET_CUSTOMERS = "GET_CUSTOMERS",
    ADD_CUSTOMER = "ADD_CUSTOMER",
    DELETE_CUSTOMER = "DELETE_CUSTOMER",
    UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
    SET_ACTIVE_CUSTOMER = "SET_ACTIVE_CUSTOMER",
    SET_ACTIVE_CUSTOMER_FROM_ID = "SET_ACTIVE_CUSTOMER_FROM_ID",
    UPDATE_MEASUREMENT = "UPDATE_MEASUREMENT",
    LOAD_REFERENTIAL = "LOAD_REFERENTIAL",
    SET_SEARCH_CUSTOMER = 'SET_SEARCH_CUSTOMER',
    RESET_CUSTOMER = "RESET_CUSTOMER",

    CUSTOMER_GET_CUSTOMERS = "customer/GET_CUSTOMERS",
    CUSTOMER_ADD_CUSTOMER = "customer/ADD_CUSTOMER",
    CUSTOMER_DELETE_CUSTOMER = "customer/DELETE_CUSTOMER",
    CUSTOMER_UPDATE_CUSTOMER = "customer/UPDATE_CUSTOMER",
    CUSTOMER_SET_ACTIVE_CUSTOMER = "customer/SET_ACTIVE_CUSTOMER",
    CUSTOMER_SET_ACTIVE_CUSTOMER_FROM_ID = "customer/SET_ACTIVE_CUSTOMER_FROM_ID",
    CUSTOMER_UPDATE_MEASUREMENT = "customer/UPDATE_MEASUREMENT",
    CUSTOMER_LOAD_REFERENTIAL = "customer/LOAD_REFERENTIAL",
    CUSTOMER_SET_SEARCH_CUSTOMER = 'customer/SET_SEARCH_CUSTOMER',
    CUSTOMER_RESET_CUSTOMER = "customer/RESET_CUSTOMER",
}