import { ActionTree, ActionContext } from "vuex";


import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { ErrorCustomMutationTypes } from "./mutation-types";
import { ErrorCustomActionTypes } from "./action-types";

import { IErrorCustom } from "@/types/IErrorCustom";
import { IErrorFromService } from "@/types/IErrorFromService.interface";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [ErrorCustomActionTypes.LOG_ERROR]({ commit }: AugmentedActionContext, payload: IErrorCustom): void;
    [ErrorCustomActionTypes.CLEAR_ERRORS]({ commit }: AugmentedActionContext): void;
    [ErrorCustomActionTypes.NEW_ERROR]({ commit }: AugmentedActionContext, payload: IErrorFromService): void;
    [ErrorCustomActionTypes.DISPLAY_ERROR]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
   
    [ErrorCustomActionTypes.LOG_ERROR]({ commit }, payload: IErrorCustom): void {
        commit(ErrorCustomMutationTypes.ADD_ERROR, payload);
        
    },
    [ErrorCustomActionTypes.NEW_ERROR]({ commit }, payload: IErrorFromService): void {
        commit(ErrorCustomMutationTypes.NEW_ERROR, payload);
    },
    [ErrorCustomActionTypes.DISPLAY_ERROR]({ commit }): void {
        commit(ErrorCustomMutationTypes.DISPLAY_ERROR, undefined);
    },
    [ErrorCustomActionTypes.CLEAR_ERRORS]({ commit }): void {
        commit(ErrorCustomMutationTypes.CLEAR_ERRORS, undefined);
    }
}