import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { AdminMutationTypes } from "./mutation-types";
import { AdminActionTypes } from "./action-types";
import { IShop } from "@/types/IShop.interface";
import adminService from "@/services/admin.service";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [AdminActionTypes.SET_ADMIN_MENU_ACTIF]({ commit }: AugmentedActionContext, payload: boolean): void;
    [AdminActionTypes.LOAD_SHOPS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [AdminActionTypes.CREATE_SHOP]({ commit }: AugmentedActionContext, payload: IShop): Promise<void>;
    [AdminActionTypes.UPDATE_SHOP]({ commit }: AugmentedActionContext, payload: IShop): Promise<void>;
    [AdminActionTypes.RESET_ADMIN](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [AdminActionTypes.GET_BACKEND_VERSION](augmentedActionContext: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    [AdminActionTypes.SET_ADMIN_MENU_ACTIF]({commit}, payload: boolean): void {
        commit(AdminMutationTypes.SET_ADMIN_MENU_ACTIF, payload);
    },
    async [AdminActionTypes.LOAD_SHOPS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      await loadShops(augmentedActionContext);
    },
    async [AdminActionTypes.CREATE_SHOP]({ commit }, payload: IShop): Promise<void> {
      const shop = await adminService.createShop(payload);
      commit(AdminMutationTypes.ADD_SHOP, shop);
    },
    async [AdminActionTypes.UPDATE_SHOP]({ commit }, payload: IShop): Promise<void> {
      const shop = await adminService.updateShop(payload);
    },
    async [AdminActionTypes.RESET_ADMIN](augmentedActionContext: AugmentedActionContext): Promise<void> {
      augmentedActionContext.commit(AdminMutationTypes.LOAD_SHOPS, [] as IShop[]);
      //await loadShops(augmentedActionContext);
      augmentedActionContext.commit(AdminMutationTypes.SET_BACKEND_VERSION, "");
      //await getBackendVersion(augmentedActionContext);
      augmentedActionContext.commit(AdminMutationTypes.SET_ADMIN_MENU_ACTIF, false);
    },
    async [AdminActionTypes.GET_BACKEND_VERSION](augmentedActionContext: AugmentedActionContext): Promise<void> {
      await getBackendVersion(augmentedActionContext);
    }
}

async function loadShops(augmentedActionContext: AugmentedActionContext): Promise<void> {
  const shops = await adminService.getShops();
  augmentedActionContext.commit(AdminMutationTypes.LOAD_SHOPS, shops);
}
async function getBackendVersion(augmentedActionContext: AugmentedActionContext): Promise<void> {
  const version = await adminService.getBackendVersion();
  //console.log(version);
  augmentedActionContext.commit(AdminMutationTypes.SET_BACKEND_VERSION, version.version);
}