
import { defineComponent } from "vue";
import TheInformations from "./components/UI/TheInformations.vue";
import TheDialogError from "./components/UI/TheDialogError.vue";
import TheLoaderFullPage from "./components/UI/TheLoaderFullPage.vue";
import { ErrorCustomActionTypes } from "./store/modules/errorCustom/action-types";
import { NavigationActionTypes } from "./store/modules/navigation/action-types";
import { mapGetters } from "vuex";

export default defineComponent({
    name: "App",
    components: {
      TheInformations,
      TheDialogError,
      TheLoaderFullPage,
    },
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters("errorCustom", ["isErrorToDisplay"]),
      ...mapGetters("navigation", ["loadingInProgress"]),
    },
    methods: {
      hideDialogError() {
        this.$store.dispatch(ErrorCustomActionTypes.ERROR_DISPLAY_ERROR);
      },
      popstateEventAction() {
        // ... some action triggered when the back button is clicked
        //console.log("popstateEventAction");
        this.$store.dispatch(NavigationActionTypes.NAVIGATION_SET_NAVIGATION_TO_PREVIOUS);
        //this.removePopstateEventAction();
      },
      // removePopstateEventAction() {
      //   window.removeEventListener('popstate', this.popstateEventAction);
      // }
    },
    created() {
      window.addEventListener('popstate', this.popstateEventAction );
    },
})
