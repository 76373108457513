import { createApp } from 'vue'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import './assets/custom.scss';

import App from './App.vue'
import router from './router'
import { store } from './store'
import { BootstrapVue3 } from 'bootstrap-vue-3';


createApp(App)
.use(store)
.use(router)
.use(BootstrapVue3)

.mount('#app')
