import { IAdjustment } from "@/types/IAdjustment.interface";
import { ILoadingRessourceInformation, INavigation } from "@/types/INavigation.interface";


export type State = {
    navigationHistoryForLog: INavigation[];
    navigationHistoryForDisplay: INavigation[];
    isModifGoingOn: boolean;
    downLevelsForModif: number;
    loadingInProgress: boolean;
    loadingRessources: ILoadingRessourceInformation[];
    homeTabSelected: string;
}

export const state: State = {
    navigationHistoryForLog: [] as INavigation[],
    navigationHistoryForDisplay: [] as INavigation[],
    isModifGoingOn: false,
    downLevelsForModif: 0,
    loadingInProgress: false,
    loadingRessources: [] as ILoadingRessourceInformation[],
    homeTabSelected: '',
}