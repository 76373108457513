export enum PurchaseMutationTypes {
    ADD_ITEM_TO_PURCHASE = "ADD_TO_PURCHASE",
    REMOVE_ITEM_FROM_PURCHASE = "REMOVE_FROM_PURCHASE",
    SET_USER_ON_PURCHASE = "SET_USER_ON_PURCHASE",
    SET_CUSTOMER_ON_PURCHASE = "SET_CUSTOMER_ON_PURCHASE",
    //CREATE_NEW_PURCHASE = "CREATE_NEW_PURCHASE",
    SET_ACTIVE_PURCHASE = "SET_ACTIVE_PURCHASE",
    SET_ACTIVE_PURCHASE_DETAIL = "SET_ACTIVE_PURCHASE_DETAIL",
    //DELETE_PURCHASE = "DELETE_PURCHASE",
    //LOAD_PURCHASES = "LOAD_PURCHASES",
    //ADD_PURCHASE = "ADD_PURCHASE",
    TOGGLE_DISPLAY_IMAGE = "TOGGLE_DISPLAY_IMAGE",
    SET_FILTERED_PURCHASES = "SET_FILTERED_PURCHASES",
    INIT_PURCHASE_FILTERS = "INIT_PURCHASE_FILTERS",
    SET_FILTER_ACTIVE = "SET_FILTER_ACTIVE",
    SET_ACTION_IN_PROGRESS = "SET_ACTION_IN_PROGRESS",
    RESET_PURCHASE = "RESET_PURCHASE",
    SET_STATUS_PURCHASE_UPDATED = 'SET_STATUS_PURCHASE_UPDATED',
    SET_FILTER_AND_SEARCH_PURCHASE = 'SET_FILTER_AND_SEARCH_PURCHASE',
    UPDATE_PURCHASE_COMMENTS = "UPDATE_PURCHASE_COMMENTS",
}