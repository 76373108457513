import constantes from "@/constantes/constantes";
import { IClothing, IClothingType } from "@/types/IClothing.interface";
import { IMeasurementType, ITemplateType } from "@/types/IMeasurement.interface";
import toolsService from "./tools.service";

const apiUrlClothing = toolsService.getApiUrl() + "v1/clothing";

export default {
    getMeasurementTypes() : Promise<IClothingType[]> {
      if (toolsService.isUserConnected()) {
        const requestOptions = toolsService.getRequestOption("GET", "");

        return toolsService.fetchRessource(apiUrlClothing + "/types", requestOptions, {}, "measurement.service.ts", "getMeasurementTypes").then(data => {
          return (data as IClothingType[]).filter(c => c.orderMeasure > 0).sort((a, b) => {
                return a.orderMeasure - b.orderMeasure;
              });
        });
      } else {
        return new Promise((resolve) => { resolve([] as IClothingType[])});
      }
    },
    async getMeasurements(idMeasurement: number) : Promise<IMeasurementType> {
      let result : IMeasurementType = {} as IMeasurementType;

      const requestOptions = toolsService.getRequestOption("GET", "");

      result = await toolsService.fetchRessource(apiUrlClothing + "/measurements/" + idMeasurement, requestOptions, idMeasurement, "measurement.service.ts", "getMeasurements");

      // to regroup item with same groupName
      for (const m of result.measurements) {
            if (
              m.rules.metadata.groupName !== null &&
              m.ihmItemUsed !== true
            ) {
              m.ihmItemsOnTheSameLine = [];
              for (const m2 of result.measurements) {
                if (
                  m2.id !== m.id &&
                  m2.ihmItemUsed !== true &&
                  m2.rules.metadata.groupName ===
                    m.rules.metadata.groupName
                ) {
                  m.ihmItemsOnTheSameLine.push(m2);
                  m2.ihmItemUsed = true;
                }
              }
            }
          
        }

        return result;

    },

    async getTemplate(idMeasurement: number): Promise<ITemplateType> {
      const requestOptions = toolsService.getRequestOption("GET", "");
      return toolsService.fetchRessource(apiUrlClothing + "/templates/" + idMeasurement, requestOptions, idMeasurement, "measurement.service.ts", "getTemplate");
    }
}