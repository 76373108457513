export enum RouterEnum {
    LOGIN_NAME = "login",
    LOGIN_LABEL = "Login",
    LOGIN_PATH = "/",

    HOME_NAME = "home",
    HOME_LABEL = "Accueil",
    HOME_PATH = "/home",

    MEASUREMENT_NAME = "measurement",
    MEASUREMENT_LABEL = "Mensurations",
    MEASUREMENT_PATH = "/measurement",

    CUSTOMER_INFOS_NAME = "customer-infos",
    CUSTOMER_INFOS_LABEL_NEW_CUSTOMER = "Nouveau client",
    CUSTOMER_INFOS_LABEL = "Infos client",
    CUSTOMER_INFOS_PATH = "/customer-infos",

    CUSTOMER_PURCHASE_NAME = "customer-purchase",
    CUSTOMER_PURCHASE_LABEL = "Création de la commande",
    CUSTOMER_PURCHASE_PATH = "/customer-purchase",

    PURCHASE_DETAILS_NAME = "purchase-details",
    PURCHASE_DETAILS_LABEL = "Options",
    PURCHASE_DETAILS_PATH = "/purchase-details",

    PURCHASE_ADJUSTMENT_NAME = "purchase-adjustment",
    PURCHASE_ADJUSTMENT_LABEL = "Retouche",
    PURCHASE_ADJUSTMENT_PATH = "/purchase-adjustment",

    PURCHASE_ADJUSTMENT_RECAP_NAME = "purchase-adjustment-recap",
    PURCHASE_ADJUSTMENT_RECAP_LABEL = "Fiche retouche",
    PURCHASE_ADJUSTMENT_RECAP_PATH = "/purchase-adjustment-recap",

    PURCHASE_RECAP_NAME = "purchase-recap",
    PURCHASE_RECAP_LABEL = "Fiche commande",
    PURCHASE_RECAP_PATH = "/purchase-recap",

    PDF_EXPORT_ADJUSTMENT_NAME = "export-adjustment",
    PDF_EXPORT_ADJUSTMENT_LABEL = "PDF retouches",
    PDF_EXPORT_ADJUSTMENT_PATH = "/pdf-export-adjustment",

    PDF_EXPORT_PURCHASE_NAME = "export-purchase",
    PDF_EXPORT_PURCHASE_LABEL = "PDF bon de commande",
    PDF_EXPORT_PURCHASE_PATH = "/pdf-export-purchase",

    RANDOM_PAGE_NAME = "dksJyqQrQc",
    RANDOM_PAGE_LABEL = "dksJyqQrQc",
    RANDOM_PAGE_PATH = "/admin/dksJyqQrQc",
}