import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IAdjustment } from "@/types/IAdjustment.interface";
import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase } from "@/types/IPurchase.interface";

export type Getters = {
    activeAdjustment(state: State): IAdjustment;
    //adjustments(state: State): IAdjustment[];
    purchaseAdjustmentFilters(state: State): IFilterPurchase[];
    //filteredPurchasesAdjustment(state: State): IPurchase[];
    isFilterActive(state: State): boolean;
    adjustmentFromPurchaseId(state: State): IAdjustment;
    nbFilterSelected(state: State): number;
    filterAndSearchAdjustment(state: State): IFilterAndSearchPurchase;
    nbSearch(state: State): number;
}

export const getters: GetterTree<State, RootState> & Getters = {
    activeAdjustment: (state) => state.activeAdjustment,
    //adjustments: (state) => state.adjustments,
    purchaseAdjustmentFilters: (state: State) => state.purchaseAdjustmentFilters,
    //filteredPurchasesAdjustment: (state: State) => state.filteredPurchasesAdjustment,
    isFilterActive: (state: State) => state.isFilterActive,
    adjustmentFromPurchaseId: (state: State) => state.adjustmentFromPurchaseId,
    nbFilterSelected: (state: State) => {
        if (state.purchaseAdjustmentFilters) {
          const filtersSelected = state.purchaseAdjustmentFilters.filter(pf => pf.isSelected);
          return filtersSelected.length;
        }
        return 0;
      },
    filterAndSearchAdjustment: (state: State) => state.filterAndSearchAdjustment,
    nbSearch: (state: State) => state.filterAndSearchAdjustment.nbSearch,
}