import { IShop } from "@/types/IShop.interface"
import type { IUser, IUserToShop } from "@/types/IUser.interface"

export type State = {
    currentUser: IUser,
    currentShop: IShop,
    errorLogin: string,
    shopUsers: IUser[],
    token: string,
    shops: IUserToShop[],
    users: IUser[],
}

export const state: State = {
    currentUser: {
    } as IUser,
    currentShop: {
        name: ""
    } as IShop,
    errorLogin: "",
    shopUsers: [] as IUser[],
    token: "",
    shops: [] as IUserToShop[],
    users: [] as IUser[],
}