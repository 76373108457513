import { MutationTree } from "vuex";

import { IUser, IUserToShop } from "@/types/IUser.interface";

import { State } from "./state";
import { UserMutationTypes as MutationTypes } from "./mutation-types";
import { IShop } from "@/types/IShop.interface";

export type Mutations<S = State> = {
    [MutationTypes.SET_USER](state: S, payload: IUser): void;
    [MutationTypes.SET_ERROR_LOGIN](state: S, payload: string): void;
    [MutationTypes.SET_SHOP](state: S, payload: IShop): void;
    [MutationTypes.SET_SHOP_USERS](state: S, payload: IUser[]): void;
    [MutationTypes.SET_TOKEN](state: S, payload: string): void;
    [MutationTypes.SET_SHOPS](state: S, payload: IUserToShop[]): void;
    [MutationTypes.LOAD_USERS](state: S, payload: IUser[]): void;
    [MutationTypes.ADD_USER](state: S, payload: IUser): void;
    [MutationTypes.UPDATE_USER](state: S, payload: IUser): void;
    [MutationTypes.DELETE_USERS](state: S): void;
    [MutationTypes.RESET_USERS](state: S): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_USER](state: State, user: IUser) {
        state.currentUser = user;
    },
    [MutationTypes.SET_ERROR_LOGIN](state: State, msg: string) {
        state.errorLogin = msg;
    },
    [MutationTypes.SET_SHOP](state: State, shop: IShop) {
        state.currentShop = shop;
    },
    [MutationTypes.SET_SHOP_USERS](state: State, users: IUser[]) {
        //console.log("SET_SHOP_USERS", users);
        state.shopUsers = users;
    },
    [MutationTypes.SET_TOKEN](state: State, token: string) {
        state.token = token;
    },
    [MutationTypes.SET_SHOPS](state: State, payload: IUserToShop[]) {
        state.shops = payload;
    },
    [MutationTypes.LOAD_USERS](state: State, users: IUser[]) {
        state.users = [...users];
    },
    [MutationTypes.ADD_USER](state: State, user: IUser) {
        state.users.push(user);
        //console.log(state.users);
        state.users = [...state.users];
        //console.log(state.users);
    },
    [MutationTypes.UPDATE_USER](state: State, user: IUser) {
        state.users = state.users.filter(u => u.id !== user.id);

        state.users.push(user);
        state.users = [...state.users];
    },
    [MutationTypes.DELETE_USERS](state: State) {
        state.users = [];
    },
    [MutationTypes.RESET_USERS](state: State) {
        state.currentUser = {
        } as IUser;
        state.currentShop = {
            name: ""
        } as IShop;
        state.errorLogin = "";
        state.shopUsers = [] as IUser[];
        state.token = "";
        state.shops = [] as IUserToShop[];
        state.users = [] as IUser[];
    }
}