import { MutationTree } from "vuex";

import { State } from "./state";
import { StockMutationTypes as MutationTypes } from "./mutation-types";
import { IShop } from "@/types/IShop.interface";
import { IStock } from "@/types/IStock";

export type Mutations<S = State> = {
    [MutationTypes.LOAD_STOCKS](state: S, payload: IStock[]): void;
    [MutationTypes.SET_IS_UPLOAD_DONE](state: S, payload: boolean): void;
    
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.LOAD_STOCKS](state: State, payload: IStock[]) {
        state.stocks = payload;
        state.lastUpdate = new Date();
    },
    [MutationTypes.SET_IS_UPLOAD_DONE](state: State, payload: boolean) {
        state.isUploadDone = payload;
    }
}