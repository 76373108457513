import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IClothing, IClothingDetails, IClothingType } from "@/types/IClothing.interface";
import { IIhmCart } from "@/types/IIhmOrder.interface";

export type Getters = {
  clothings(state: State): IClothing[];
  clothingDetails(state: State): IClothingDetails[];
  activeClothingDetails(state: State): IClothingDetails | undefined;
  activeCart(state: State): IIhmCart;
  cartMaxId(state: State): number;
  activeItemId(state: State): number;
  carts(state: State): IIhmCart[];
  clothingTypes(state: State): IClothingType[];
};

export const getters: GetterTree<State, RootState> & Getters = {
  clothings: (state) => state.clothings,
  clothingDetails: (state) => state.clothingDetails,
  activeClothingDetails: (state) => state.activeClothingDetails,
  activeCart: (state) => state.activeCart,
  cartMaxId: (state) => {
    let maxId = 0;

    for (const i of state.activeCart.orders) {
        if (maxId < i.id) {
            maxId = i.id;
        }
    }

    return maxId;
  },
  activeItemId: (state) => state.activeItemId,
  carts: (state) => state.carts,
  clothingTypes: (state) => state.clothingTypes,
};
