export enum UserActionTypes {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    SET_CURRENT_SHOP = "SET_CURRENT_SHOP",
    LOAD_USERS = "LOAD_USERS",
    CREATE_USER = "CREATE_USER",
    UPDATE_USER = "UPDATE_USER",
    RESET_USERS = "RESET_USERS",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    RESET_PASSWORD = "RESET_PASSWORD",

    USER_LOGIN = "user/LOGIN",
    USER_LOGOUT = "user/LOGOUT",
    USER_SET_CURRENT_SHOP = "user/SET_CURRENT_SHOP",
    USER_LOAD_USERS = "user/LOAD_USERS",
    USER_CREATE_USER = "user/CREATE_USER",
    USER_UPDATE_USER = "user/UPDATE_USER",
    USER_RESET_USERS = "user/RESET_USERS",
    USER_CHANGE_PASSWORD = "user/CHANGE_PASSWORD",
    USER_RESET_PASSWORD = "user/RESET_PASSWORD",
}