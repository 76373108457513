
import { INavigation } from "@/types/INavigation.interface";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export interface DisplayNavigation {
    date: string;
    label: string;
    nbTab: number;
}

export default defineComponent({
  
    name: "NavigationRecap",
    data() {
        return {
            nbTab: 0,
            display: false,
        };
    },
    computed: {
        ...mapGetters('navigation', ["navigationHistoryForLog"]),
        history(): INavigation[] {
            if (this.navigationHistoryForLog) {
                const tab = this.navigationHistoryForLog as INavigation[];
                return tab;
            } else {
                return [] as INavigation[];
            }
        },
    },
    methods: {
        formatDate(date: string) {
            if (date && date.length >= 10) {
                return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4);
            } else {
                return "";
            }
        },
        getNbTab(isForward: boolean): number {
            if (isForward) {
                this.nbTab++;
            } else {
                this.nbTab--;
            }
            return this.nbTab;
        }
    }
})
