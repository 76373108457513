
import { defineComponent } from "vue";

export default defineComponent({
    name: "TheLoader",
    props: {
      color: {
        type: Number,
        required: false,
      }
    },
    computed: {
      loaderClass() {
        if (this.color && this.color === 2) {
          return "lds-ellipsis-red";
        }
        return "lds-ellipsis";
      }
    }
    
})
