import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase, IPurchaseDetail } from "@/types/IPurchase.interface"

export type State = {
    activePurchase: IPurchase,
    activePurchaseDetail: IPurchaseDetail,
    //purchases: IPurchase[],
    displayImages: boolean,
    isFilterActive: boolean,
    purchaseFilters: IFilterPurchase[],
    actionInProgress: boolean,
    statusPurchaseUpdated: number,
    filterAndSearchPurchase: IFilterAndSearchPurchase,
}

export const state: State = {
    activePurchase: {
        id: 0,
        cause: "",
        productionDate: "",
        fittingDate: "",
        deadlineDate: "",
        customerId: 0,
        userId: 0,
        shopId: 0,
    } as IPurchase,
    activePurchaseDetail: {} as IPurchaseDetail,
    //purchases: [] as IPurchase[],
    displayImages: true,
    isFilterActive: false,
    purchaseFilters: [] as IFilterPurchase[],
    actionInProgress: false,
    statusPurchaseUpdated: 0,
    filterAndSearchPurchase: {
        filters: [],
        searchInput: '',
        nbSearch: 0,
    },
}