export enum AdjustmentActionTypes {
    SET_ACTIVE_ADJUSTMENT = "SET_ACTIVE_ADJUSTMENT",
    SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID = "SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID",
    //LOAD_ADJUSTMENTS = "LOAD_ADJUSTMENTS",
    RESET_ADJUSTMENTS = "RESET_ADJUSTMENTS",
    CREATE_ADJUSTMENT = "CREATE_ADJUSTMENT",
    UPDATE_ADJUSTMENT = "UPDATE_ADJUSTMENT",
    SET_FILTERED_PURCHASES_ADJUSTMENT = "SET_FILTERED_PURCHASES_ADJUSTMENT",
    INIT_PURCHASE_ADJUSTMENT_FILTERS = "INIT_PURCHASE_ADJUSTMENT_FILTERS",
    GET_ADJUSTMENTS_FROM_PURCHASE_ID = "GET_ADJUSTMENTS_FROM_PURCHASE_ID",
    SET_FILTER_AND_SEARCH_ADJUSTMENT = 'SET_FILTER_AND_SEARCH_ADJUSTMENT',
    UPDATE_ADJUSTMENT_COMMENTS = "UPDATE_ADJUSTMENT_COMMENTS",

    ADJUSTMENT_SET_ACTIVE_ADJUSTMENT = "adjustment/SET_ACTIVE_ADJUSTMENT",
    ADJUSTMENT_SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID = "adjustment/SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID",
    //ADJUSTMENT_LOAD_ADJUSTMENTS = "adjustment/LOAD_ADJUSTMENTS",
    ADJUSTMENT_RESET_ADJUSTMENTS = "adjustment/RESET_ADJUSTMENTS",
    ADJUSTMENT_CREATE_ADJUSTMENT = "adjustment/CREATE_ADJUSTMENT",
    ADJUSTMENT_UPDATE_ADJUSTMENT = "adjustment/UPDATE_ADJUSTMENT",
    ADJUSTMENT_INIT_NEW_ADJUSTMENT = "adjustment/INIT_NEW_ADJUSTMENT",
    ADJUSTMENT_SET_FILTERED_PURCHASES_ADJUSTMENT = "adjustment/SET_FILTERED_PURCHASES_ADJUSTMENT",
    ADJUSTMENT_INIT_PURCHASE_ADJUSTMENT_FILTERS = "adjustment/INIT_PURCHASE_ADJUSTMENT_FILTERS",
    ADJUSTMENT_GET_ADJUSTMENTS_FROM_PURCHASE_ID = "adjustment/GET_ADJUSTMENTS_FROM_PURCHASE_ID",
    ADJUSTMENT_SET_FILTER_AND_SEARCH_ADJUSTMENT = 'adjustment/SET_FILTER_AND_SEARCH_ADJUSTMENT',
    ADJUSTMENT_UPDATE_ADJUSTMENT_COMMENTS = "adjustment/UPDATE_ADJUSTMENT_COMMENTS",
}