import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IClothing, IClothingType } from "@/types/IClothing.interface";
import { IMeasurementType, ITemplateType } from "@/types/IMeasurement.interface";

export type Getters = {
  measurementRef(state: State): IClothingType[];
  measurementDetails(state: State): IMeasurementType[];
  activeMeasurementType(state: State): IMeasurementType | undefined;
  templates(state: State): ITemplateType[];
};

export const getters: GetterTree<State, RootState> & Getters = {
  measurementRef: (state) => state.measurementRef,
  measurementDetails: (state) => state.measurementDetails,
  activeMeasurementType: (state) => state.activeMeasurementType,
  templates: (state) => state.templates,
};
