import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { state, State } from "./state";
import { Mutations } from './mutations';
import { Getters } from "./getters";
import { ClothingMutationTypes } from "./mutation-types";
import { ClothingActionTypes } from "./action-types";

import clothingService from "@/services/clothing.service";
import { IClothing, IClothingDetails } from "@/types/IClothing.interface";
import { IIhmCart, IIhmOrder } from "@/types/IIhmOrder.interface";
import { IUser } from "@/types/IUser.interface";
import { ICustomer } from "@/types/ICustomer.interface";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>
      };
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [ClothingActionTypes.LOAD_CLOTHING_REF](augmentedActionContext : AugmentedActionContext): void;
    [ClothingActionTypes.SET_ACTIVE_CLOTHING_DETAILS]({ commit }: AugmentedActionContext, payload: number): void;
    [ClothingActionTypes.ADD_TO_CART]({ commit }: AugmentedActionContext, payload: IIhmOrder): number;
    [ClothingActionTypes.REMOVE_FROM_CART]({ commit }: AugmentedActionContext, payload: number): void;
    [ClothingActionTypes.UPDATE_ORDER_IN_CART]({ commit }: AugmentedActionContext, payload: IIhmOrder): void;
    [ClothingActionTypes.SET_ACTIVE_ITEM_ID]({ commit }: AugmentedActionContext, payload: number): void;
    [ClothingActionTypes.SET_USER_ON_CART]({ commit }: AugmentedActionContext, payload: IUser): void;
    [ClothingActionTypes.SET_CUSTOMER_ON_CART]({ commit }: AugmentedActionContext, payload: ICustomer): void;
    [ClothingActionTypes.CREATE_NEW_ORDER]({ commit, getters }: AugmentedActionContext, payload: ICustomer): void;
    [ClothingActionTypes.SET_ACTIVE_CART]({ commit }: AugmentedActionContext, payload: IIhmCart): void;
    [ClothingActionTypes.DELETE_CART]({ commit }: AugmentedActionContext, payload: IIhmCart): void;
    [ClothingActionTypes.RESET_CLOTHING](augmentedActionContext : AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [ClothingActionTypes.LOAD_CLOTHING_REF](augmentedActionContext: AugmentedActionContext): Promise<void> {
        await loadRef(augmentedActionContext);
    },
    async [ClothingActionTypes.SET_ACTIVE_CLOTHING_DETAILS]({commit, getters}, clothingId: number): Promise<void> {
        const details = getters.clothingDetails as IClothingDetails[];
        const clothingDetails = details.find(clothing => {
            if (clothing.id === clothingId) {
                return true;
            }
        });
        commit(ClothingMutationTypes.SET_ACTIVE_CLOTHING_DETAILS, clothingDetails);
    },
    [ClothingActionTypes.ADD_TO_CART]({commit, getters}, item: IIhmOrder): number {
        const maxId = getters.cartMaxId as number;
        item.id = maxId + 1;
        commit(ClothingMutationTypes.ADD_TO_CART, item);
        return item.id;
    },
    async [ClothingActionTypes.REMOVE_FROM_CART]({commit}, id: number): Promise<void> {
        commit(ClothingMutationTypes.REMOVE_FROM_CART, id);
    },
    async [ClothingActionTypes.UPDATE_ORDER_IN_CART]({commit}, item: IIhmOrder): Promise<void> {
        commit(ClothingMutationTypes.REMOVE_FROM_CART, item.id);
        commit(ClothingMutationTypes.ADD_TO_CART, item);
    },
    [ClothingActionTypes.SET_ACTIVE_ITEM_ID]({ commit }, id: number): void {
        commit(ClothingMutationTypes.SET_ACTIVE_ITEM_ID, id);
    },
    [ClothingActionTypes.SET_USER_ON_CART]({ commit }, user: IUser): void {
        commit(ClothingMutationTypes.SET_USER_ON_CART, user);
    },
    [ClothingActionTypes.SET_CUSTOMER_ON_CART]({ commit }, customer: ICustomer): void {
        commit(ClothingMutationTypes.SET_CUSTOMER_ON_CART, customer);
    },
    [ClothingActionTypes.CREATE_NEW_ORDER]({ commit, getters}, customer: ICustomer): void {
        const cart = {
            id: 0,
            customer: null,
            orders: [] as IIhmOrder[],
            state: 0,
            buyDate: new Date()
        } as IIhmCart;
        if (customer) {
            cart.customer = customer;
        }
        commit(ClothingMutationTypes.CREATE_NEW_ORDER, cart);
        commit(ClothingMutationTypes.SET_ACTIVE_CART, cart);
    },
    [ClothingActionTypes.SET_ACTIVE_CART]({ commit }, cart: IIhmCart): void {
        commit(ClothingMutationTypes.SET_ACTIVE_CART, cart);
    },
    [ClothingActionTypes.DELETE_CART]({ commit }, cart: IIhmCart): void {
        commit(ClothingMutationTypes.DELETE_CART, cart);
    },
    async [ClothingActionTypes.RESET_CLOTHING](augmentedActionContext: AugmentedActionContext): Promise<void> {
        augmentedActionContext.commit(ClothingMutationTypes.DELETE_REF, undefined);
        //await loadRef(augmentedActionContext);
        augmentedActionContext.commit(ClothingMutationTypes.SET_ACTIVE_CLOTHING_DETAILS, {} as IClothingDetails);
        augmentedActionContext.commit(ClothingMutationTypes.SET_ACTIVE_CART, {
            id: 0,
            user: {} as IUser,
            customer: null,
            orders: [] as IIhmOrder[]
        } as IIhmCart);
        augmentedActionContext.commit(ClothingMutationTypes.SET_ACTIVE_ITEM_ID, 0);
    },
}

async function loadRef({ commit, getters }: AugmentedActionContext): Promise<void> {
    const clothings = getters.clothings as IClothing[];

    if (clothings && clothings.length > 0) {
    } else {
        const clothings = await clothingService.getClothings();

        commit(ClothingMutationTypes.SET_CLOTHINGS_REF, clothings);

        for (const clothing of clothings) {
            try {
                const details = await clothingService.getClothingDetails(clothing.id);
                commit(ClothingMutationTypes.ADD_CLOTHING_DETAILS, details);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const clothingTypes = await clothingService.getClothingTypes();
    commit(ClothingMutationTypes.SET_CLOTHING_TYPES, clothingTypes);
}