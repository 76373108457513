import { ActionTree, ActionContext } from "vuex";

import { ICustomer, ICustomerReferential, ICustomerToMeasurement } from "@/types/ICustomer.interface";
import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { CustomerMutationTypes } from "./mutation-types";
import { CustomerActionTypes } from "./action-types";

import customerService from "@/services/customer.service";
import { IShop } from "@/types/IShop.interface";
import { IFilterAndSearchPurchase } from "@/types/IPurchase.interface";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [CustomerActionTypes.GET_CUSTOMERS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [CustomerActionTypes.ADD_CUSTOMER](augmentedActionContext: AugmentedActionContext, payload: ICustomer): Promise<boolean>;
    [CustomerActionTypes.DELETE_CUSTOMER]({ commit }: AugmentedActionContext, payload: ICustomer): Promise<boolean>;
    [CustomerActionTypes.UPDATE_CUSTOMER]({ commit }: AugmentedActionContext, payload: ICustomer): Promise<boolean>;
    [CustomerActionTypes.SET_ACTIVE_CUSTOMER]({ commit }: AugmentedActionContext, payload: ICustomer): Promise<ICustomer>;
    [CustomerActionTypes.SET_ACTIVE_CUSTOMER_FROM_ID]({ commit }: AugmentedActionContext, payload: number): Promise<ICustomer>;
    [CustomerActionTypes.UPDATE_MEASUREMENT]({ commit }: AugmentedActionContext, payload: ICustomerToMeasurement[]): void;
    [CustomerActionTypes.LOAD_REFERENTIAL](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [CustomerActionTypes.SET_SEARCH_CUSTOMER](augmentedActionContext: AugmentedActionContext, payload: IFilterAndSearchPurchase): void;
    [CustomerActionTypes.RESET_CUSTOMER](augmentedActionContext: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CustomerActionTypes.GET_CUSTOMERS](augmentedActionContext: AugmentedActionContext): Promise<void> {
        await loadCustomers(augmentedActionContext);
    },
    async [CustomerActionTypes.ADD_CUSTOMER](augmentedActionContext, payload: ICustomer): Promise<boolean> {
        const customer = await customerService.createCustomer(payload);
        if (customer && customer.id > 0) {
            augmentedActionContext.commit(CustomerMutationTypes.SET_ACTIVE_CUSTOMER, customer);
            //await loadCustomers(augmentedActionContext);
            return true;
        } else {
            return false;
        }
    },
    async [CustomerActionTypes.DELETE_CUSTOMER](augmentedActionContext, payload: ICustomer): Promise<boolean> {
        try {
            await customerService.deleteCustomer(payload);

        } catch(err) {
            console.log(err);
        }
        return true;
        //await loadCustomers(augmentedActionContext);
    },
    async [CustomerActionTypes.UPDATE_CUSTOMER](augmentedActionContext, payload: ICustomer): Promise<boolean> {
        const customer = await customerService.updateCustomer(payload);
        if (customer && customer.id > 0) {
            //await loadCustomers(augmentedActionContext);
            augmentedActionContext.commit(CustomerMutationTypes.SET_ACTIVE_CUSTOMER, customer);
            return true;
        } else {
            return false;
        }
    },
    async [CustomerActionTypes.SET_ACTIVE_CUSTOMER]({commit}, payload: ICustomer): Promise<ICustomer> {
        if (payload && payload.id !== 0) {
            const customer = await customerService.getCustomer(payload);
            commit(CustomerMutationTypes.SET_ACTIVE_CUSTOMER, customer);
            return new Promise(resolve => resolve(customer));
        } else {
            commit(CustomerMutationTypes.SET_ACTIVE_CUSTOMER, payload);
            return new Promise(resolve => resolve(payload));
        }
        
    },
    async [CustomerActionTypes.SET_ACTIVE_CUSTOMER_FROM_ID]({commit}, payload: number): Promise<ICustomer> {
        if (payload !== 0) {
            const customer = await customerService.getCustomerFromId(payload);
            commit(CustomerMutationTypes.SET_ACTIVE_CUSTOMER, customer);
            return new Promise(resolve => resolve(customer));
        }
        return new Promise(resolve => resolve({} as ICustomer));
    },

    [CustomerActionTypes.UPDATE_MEASUREMENT]({commit}, payload: ICustomerToMeasurement[]): void {
        commit(CustomerMutationTypes.UPDATE_MEASUREMENT, payload);
    },
    async [CustomerActionTypes.LOAD_REFERENTIAL](augmentedActionContext: AugmentedActionContext): Promise<void> {
        await loadReferential(augmentedActionContext);
    },
    [CustomerActionTypes.SET_SEARCH_CUSTOMER](augmentedActionContext: AugmentedActionContext, payload: IFilterAndSearchPurchase) {
        augmentedActionContext.commit(CustomerMutationTypes.SET_SEARCH_CUSTOMER, payload);
    },

    async [CustomerActionTypes.RESET_CUSTOMER](augmentedActionContext: AugmentedActionContext): Promise<void> {
        //augmentedActionContext.commit(CustomerMutationTypes.SET_CUSTOMERS, [] as ICustomer[]);
        //await loadCustomers(augmentedActionContext);
        //await loadReferential(augmentedActionContext);
        augmentedActionContext.commit(CustomerMutationTypes.RESET_CUSTOMER, undefined);
    }
}

async function loadCustomers(augmentedActionContext: AugmentedActionContext): Promise<void> {
    try {   
        // const customers = await customerService.getCustomers();
        // const sortedCustomers = [...customers.sort((c1, c2) => (new Date(c2.createdAt)).getTime() - (new Date(c1.createdAt)).getTime())];
        // augmentedActionContext.commit(CustomerMutationTypes.SET_CUSTOMERS, sortedCustomers);
    } catch (error) {
        console.log(error);
    }
}

async function loadReferential(augmentedActionContext: AugmentedActionContext): Promise<void> {
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_HEIGHT, [] as ICustomerReferential[]);
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_WEIGHT, [] as ICustomerReferential[]);
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_SHOES_SIZE, [] as ICustomerReferential[]);
    const refHeight =  await customerService.getReferentialHeight();
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_HEIGHT, refHeight);
    const refWeight =  await customerService.getReferentialWeight();
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_WEIGHT, refWeight);
    const refShoesSize =  await customerService.getReferentialShoesSize();
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_SHOES_SIZE, refShoesSize);
    const refOrigin = await customerService.getReferentielOrigin();
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_ORIGIN, refOrigin);
    const refMotivation = await customerService.getReferentielMotivation();
    augmentedActionContext.commit(CustomerMutationTypes.SET_REFERENTIAL_MOTIVATION, refMotivation);
}