import { MutationTree } from "vuex";

import { State } from "./state";
import { NavigationMutationTypes as MutationTypes } from "./mutation-types";
import { IAdjustment } from "@/types/IAdjustment.interface";
import { ILoadingRessourceInformation, INavigation } from "@/types/INavigation.interface";
import { RouterEnum } from "@/router/router.enum";

export type Mutations<S = State> = {
    [MutationTypes.NAVIGATE_BACKWARD](state: S, payload: INavigation): void;
    [MutationTypes.NAVIGATE_FORWARD](state: S, payload: INavigation): void;
    [MutationTypes.NAVIGATE_TO_PREVIOUS](state: S): void;
    [MutationTypes.RESET_NAVIGATION](state: S): void;
    [MutationTypes.CHANGE_MODIFY_STATUS](state: S, payload: boolean): void;
    [MutationTypes.SET_IS_MODIF_GOING_ON](state: S, payload: boolean): void;
    [MutationTypes.NAVIGATE_FROM_THE_BEGINNING](state: S): void;
    [MutationTypes.SET_LOADING_IN_PROGRESS](state: S, payload: ILoadingRessourceInformation): void;
    [MutationTypes.CLEAR_LOADING_IN_PROGRESS](state: S): void;
    [MutationTypes.SET_HOME_TAB_SELECTED](state: S, payload: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.NAVIGATE_BACKWARD](state: State, payload: INavigation) {
        navigateBackward(state, payload);
    },
    [MutationTypes.NAVIGATE_FORWARD](state: State, payload: INavigation) {
        payload.date = (new Date()).toLocaleString();
        payload.forward = true;
        state.navigationHistoryForLog.push(payload);
        if (state.navigationHistoryForDisplay && 
            state.navigationHistoryForDisplay.length > 0 && 
            state.navigationHistoryForDisplay[state.navigationHistoryForDisplay.length -1].name === payload.name) {
                console.log("we don't save it");
            } else {
                state.navigationHistoryForDisplay.push(payload);
                //console.log("NAVIGATE_FORWARD", payload, state);
                if (state.isModifGoingOn) {
                    state.downLevelsForModif = state.downLevelsForModif + 1;
                }
            }
        
    },
    [MutationTypes.NAVIGATE_TO_PREVIOUS](state: State) {
        if (state.navigationHistoryForDisplay && state.navigationHistoryForDisplay.length > 1) {
            const indexTarget = state.navigationHistoryForDisplay.length - 2;
            navigateBackward(state, state.navigationHistoryForDisplay[indexTarget]);
        }
    },
    [MutationTypes.CHANGE_MODIFY_STATUS](state: State, payload: boolean) {
        state.navigationHistoryForDisplay[state.navigationHistoryForDisplay.length -1].canModifyData = payload;
        state.navigationHistoryForLog[state.navigationHistoryForDisplay.length -1].canModifyData = payload;
    },
    [MutationTypes.SET_IS_MODIF_GOING_ON](state: State, payload: boolean) {
        state.isModifGoingOn = payload;
        state.downLevelsForModif = 0;
    },
    [MutationTypes.NAVIGATE_FROM_THE_BEGINNING](state: State) {
        navigateBackward(state, {
            label: RouterEnum.HOME_LABEL,
            name: RouterEnum.HOME_NAME,
            path: RouterEnum.HOME_PATH,
            canModifyData: false
        } as INavigation);
    },
    [MutationTypes.SET_LOADING_IN_PROGRESS](state: State, payload: ILoadingRessourceInformation) {
        if (payload.isLoading) {
            state.loadingRessources.push(payload);
            state.loadingInProgress = true;
        } else {
            setTimeout(() => {
                state.loadingRessources = [...state.loadingRessources.filter(lr => lr.url !== payload.url)];

                // const tabTemp = [] as ILoadingRessourceInformation[];
                // for (const item of state.loadingRessources) {
                //     if (item.url !== payload.url) {
                //         tabTemp.push(JSON.parse(JSON.stringify(item)));
                //     }
                // }

                // state.loadingRessources = [...tabTemp];

                if (state.loadingRessources.length === 0) {
                    state.loadingInProgress = false;
                    state.loadingRessources = [] as ILoadingRessourceInformation[];
                }
            }, 300);
        }
    },
    [MutationTypes.CLEAR_LOADING_IN_PROGRESS](state: State) {
        state.loadingRessources = [];
        state.loadingInProgress = false;
    },
    [MutationTypes.SET_HOME_TAB_SELECTED](state: State, payload: string) {
        state.homeTabSelected = payload;
    },

    [MutationTypes.RESET_NAVIGATION](state: State) {
        state.navigationHistoryForLog = [] as INavigation[];
        state.navigationHistoryForDisplay = [] as INavigation[];
        state.isModifGoingOn = false;
        state.downLevelsForModif = 0;
        state.loadingInProgress = false;
        state.loadingRessources = [] as ILoadingRessourceInformation[];
    }
}

function navigateBackward(state: State, payload: INavigation) {
    //console.log("navigateBackward payload ", payload);
    payload.date = (new Date()).toLocaleString();
    payload.forward = false;
    state.navigationHistoryForLog.push(payload);
    // delete from navigationHistoryForDisplay the item that are between the end and the item where we want to go
    let itemFound = false;
    for (let index = (state.navigationHistoryForDisplay.length - 1); index >= 0 ; index--) {
        if (state.navigationHistoryForDisplay[index].path !== payload.path && !itemFound) {
            // we delete that item from the history
            state.navigationHistoryForDisplay.splice(index, 1);

            if (state.isModifGoingOn) {
                state.downLevelsForModif = state.downLevelsForModif - 1;
            }
        } else  if (!itemFound) {
            itemFound = true;
            // if (state.isModifGoingOn) {
            //     state.downLevelsForModif = state.downLevelsForModif - 1;
            // }
        }
    }
    //console.log("NAVIGATE_BACKWARD", payload, state);
}