export enum ErrorCustomActionTypes {
    
    LOG_ERROR = "LOG_ERROR",
    CLEAR_ERRORS = "CLEAR_ERRORS",
    NEW_ERROR = "NEW_ERROR",
    DISPLAY_ERROR = "DISPLAY_ERROR",

    
    ERROR_CUSTOM_LOG_ERROR = "errorCustom/LOG_ERROR",
    ERROR_CUSTOM_CLEAR_ERRORS = "errorCustom/CLEAR_ERRORS",
    ERROR_NEW_ERROR = "errorCustom/NEW_ERROR",
    ERROR_DISPLAY_ERROR = "errorCustom/DISPLAY_ERROR",
}