import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IStock } from "@/types/IStock";

export type Getters = {
    stocks(state: State): IStock[];
    lastUpdate(state: State): Date | undefined;
    isUploadDone(state: State): boolean;
}

export const getters: GetterTree<State, RootState> & Getters = {
    stocks: (state) => state.stocks,
    lastUpdate: (state) => state.lastUpdate,
    isUploadDone: (state) => state.isUploadDone,
}