import { IErrorCustom } from "@/types/IErrorCustom"
import { IErrorFromService } from "@/types/IErrorFromService.interface";

export type State = {
    errors: IErrorCustom[];
    errorsFromService: IErrorFromService[];
    isErrorToDisplay: boolean;
}

export const state: State = {
    errors: [] as IErrorCustom[],
    errorsFromService: [] as IErrorFromService[],
    isErrorToDisplay: false,
}