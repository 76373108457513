import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IShop } from "@/types/IShop.interface";

export type Getters = {
    adminMenuActif(state: State): boolean;
    shops(state: State): IShop[];
    activeShops(state: State): IShop[];
    backendVersion(state: State): string;
}

export const getters: GetterTree<State, RootState> & Getters = {
    adminMenuActif: (state) => state.adminMenuActif,
    shops: (state) => state.shops,
    activeShops: (state) => state.shops.filter(s => s.isActive),
    backendVersion: (state) => state.backendVersion,
}