import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IErrorCustom } from "@/types/IErrorCustom";
import { IErrorFromService } from "@/types/IErrorFromService.interface";

export type Getters = {
    errors(state: State): IErrorCustom[];
    errorsFromService(state: State): IErrorFromService[];
    isErrorToDisplay(state: State): boolean;
}

export const getters: GetterTree<State, RootState> & Getters = {
    errors: (state) => state.errors,
    errorsFromService: (state) => state.errorsFromService,
    isErrorToDisplay: (state) => state.isErrorToDisplay,
}