import { MutationTree } from "vuex";

import { State } from "./state";
import { AdminMutationTypes as MutationTypes } from "./mutation-types";
import { IShop } from "@/types/IShop.interface";

export type Mutations<S = State> = {
    [MutationTypes.SET_ADMIN_MENU_ACTIF](state: S, payload: boolean): void;
    [MutationTypes.LOAD_SHOPS](state: S, payload: IShop[]): void;
    [MutationTypes.ADD_SHOP](state: S, payload: IShop): void;
    [MutationTypes.UPDATE_SHOP](state: S, payload: IShop): void;
    [MutationTypes.SET_BACKEND_VERSION](state: S, payload: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_ADMIN_MENU_ACTIF](state: State, isActif: boolean) {
        state.adminMenuActif = isActif;
    },
    [MutationTypes.LOAD_SHOPS](state: State, payload: IShop[]) {
        state.shops = payload;
    },
    [MutationTypes.ADD_SHOP](state: State, payload: IShop) {
        state.shops.push(payload);
    },
    [MutationTypes.UPDATE_SHOP](state: State, payload: IShop) {
        state.shops = state.shops.filter(s => s.id !== payload.id);

        state.shops.push(payload);
        state.shops = [...state.shops];
    },
    [MutationTypes.SET_BACKEND_VERSION](state: State, payload: string) {
        state.backendVersion = payload;
    }
}