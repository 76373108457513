import { MutationTree } from "vuex";

import { State } from "./state";
import { ClothingMutationTypes as MutationTypes } from "./mutation-types";
import { IClothing, IClothingDetails, IClothingType } from "@/types/IClothing.interface";
import { IIhmCart, IIhmOrder } from "@/types/IIhmOrder.interface";
import { IUser } from "@/types/IUser.interface";
import { ICustomer } from "@/types/ICustomer.interface";

export type Mutations<S = State> = {
    [MutationTypes.SET_CLOTHINGS_REF](state: S, payload: IClothing[]): void;
    [MutationTypes.ADD_CLOTHING_DETAILS](state: S, payload: IClothingDetails): void;
    [MutationTypes.SET_ACTIVE_CLOTHING_DETAILS](state: S, payload: IClothingDetails | undefined): void;
    [MutationTypes.ADD_TO_CART](state: S, payload: IIhmOrder): void;
    [MutationTypes.REMOVE_FROM_CART](state: S, payload: number): void;
    [MutationTypes.SET_ACTIVE_ITEM_ID](state: S, payload: number): void;
    [MutationTypes.SET_USER_ON_CART](state: S, payload: IUser): void;
    [MutationTypes.SET_CUSTOMER_ON_CART](state: S, payload: ICustomer): void;
    [MutationTypes.CREATE_NEW_ORDER](state: S, payload: IIhmCart): void;
    [MutationTypes.SET_ACTIVE_CART](state: S, payload: IIhmCart): void;
    [MutationTypes.DELETE_CART](state: S, payload: IIhmCart): void;
    [MutationTypes.DELETE_REF](state: S): void;
    [MutationTypes.SET_CLOTHING_TYPES](state: S, payload: IClothingType[]): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_CLOTHINGS_REF](state: State, clothings: IClothing[]) {
        state.clothings = clothings;
    },
    [MutationTypes.ADD_CLOTHING_DETAILS](state: State, details: IClothingDetails) {
        state.clothingDetails.push(details);
        state.clothingDetails = [...state.clothingDetails];
    },
    [MutationTypes.SET_ACTIVE_CLOTHING_DETAILS](state: State, details: IClothingDetails | undefined) {
        state.activeClothingDetails = details;
    },
    [MutationTypes.ADD_TO_CART](state: State, item: IIhmOrder) {
        state.activeCart.orders.push(item);
        state.activeCart.orders = [...state.activeCart.orders];
    },
    [MutationTypes.REMOVE_FROM_CART](state: State, id: number) {
        state.activeCart.orders.filter((item, index, arr) => {
            if (item.id === id) {
                arr.splice(index, 1);
                return false;
            } else {
                return true;
            }
        });
        state.activeCart.orders = [...state.activeCart.orders];
    },
    [MutationTypes.SET_ACTIVE_ITEM_ID](state: State, id: number) {
        state.activeItemId = id;
    },
    [MutationTypes.SET_USER_ON_CART](state: State, user: IUser) {
        state.activeCart.user = user;
    },
    [MutationTypes.SET_CUSTOMER_ON_CART](state: State, customer: ICustomer) {
        state.activeCart.customer = customer;
    },
    [MutationTypes.CREATE_NEW_ORDER](state: State, cart: IIhmCart) {

        // get max id
        let id = 1;
        for (const c of state.carts) {
            if (c.id > id) {
                id = c.id;
            }
        }
        cart.id = id + 1;

        state.carts.push(cart);
        state.carts = [...state.carts];
    },
    [MutationTypes.SET_ACTIVE_CART](state: State, cart: IIhmCart) {
        state.activeCart = cart;
    },
    [MutationTypes.DELETE_CART](state: State, cart: IIhmCart) {
        state.carts.filter((item, index, arr) => {
            if (item.id === cart.id) {
                arr.splice(index, 1);
                return false;
            } else {
                return true;
            }
        });
        state.carts = [...state.carts];
    },
    [MutationTypes.DELETE_REF](state: State) {
        state.clothings = [] as IClothing[];
        state.clothingDetails = [] as IClothingDetails[];
        state.clothingTypes = [] as IClothingType[];
        state.carts = [] as IIhmCart[];
    },
    [MutationTypes.SET_CLOTHING_TYPES](state: State, payload: IClothingType[]): void {
        state.clothingTypes = [...payload];
    }
}