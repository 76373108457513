
import {DialogEvents} from "@/constantes/events";
import { IErrorFromService } from "@/types/IErrorFromService.interface";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
    name: "TheDialogError",
    emits: [DialogEvents.DIALOG_CLOSE_VALID],
    data() {
        return {
            displayMoreInformations: false,
        };
    },
    computed: {
        ...mapGetters("errorCustom", ["errorsFromService"]),
        errorToDisplay(): IErrorFromService {
            if (this.errorsFromService && this.errorsFromService.length > 0) {
                return this.errorsFromService[this.errorsFromService.length - 1];
            } else {
                return {} as IErrorFromService;
            }
        }
    },
    methods: {
        validation() {
            this.$emit(DialogEvents.DIALOG_CLOSE_VALID);
        }
    }
})
