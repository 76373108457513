
import { defineComponent } from "vue";
import { IUser } from "@/types/IUser.interface";
import { mapGetters } from "vuex";
import { UserActionTypes } from "@/store/modules/user/action-types";
import TheLoader from "@/components/UI/TheLoader.vue";
import { NavigationActionTypes } from "@/store/modules/navigation/action-types";
import { INavigation } from "@/types/INavigation.interface";
import { RouterEnum } from "@/router/router.enum";
import TheDialogChangePassword from "@/components/UI/TheDialogChangePassword.vue";
import { AdminActionTypes } from "@/store/modules/admin/action-types";
import { PurchaseActionTypes } from "@/store/modules/purchase/action-types";
import { AdjustmentActionTypes } from "@/store/modules/adjustment/action-types";
import { IFilterAndSearchPurchase } from "@/types/IPurchase.interface";
import { CustomerActionTypes } from "@/store/modules/customer/action-types";

export default defineComponent({
  name: "LoginView",
  components: { TheLoader, TheDialogChangePassword },
  data() {
    return {
      userInput: {
        email: "",
        password: "",
      } as IUser,
      isLoading: false,
      isDialogChangePasswordVisible: false
    };
  },
  computed: {
    ...mapGetters('user', ["errorLogin", "currentUser"]),
  },
  methods: {
    async login() {
      // console.log("btn login");
      this.isLoading = true;
      this.$store.dispatch(AdminActionTypes.ADMIN_SET_ADMIN_MENU_ACTIF, false);
      const result = await this.$store.dispatch(UserActionTypes.USER_LOGIN, this.userInput);
      if (result === true) {
        if (this.currentUser && this.currentUser.temporaryPassword === false) {
          this.$store.dispatch(PurchaseActionTypes.PURCHASE_INIT_PURCHASE_FILTERS);
          this.$store.dispatch(AdjustmentActionTypes.ADJUSTMENT_INIT_PURCHASE_ADJUSTMENT_FILTERS);
          this.$store.dispatch(NavigationActionTypes.NAVIGATION_SET_HOME_TAB_SELECTED, '');
          this.$store.dispatch(CustomerActionTypes.CUSTOMER_SET_SEARCH_CUSTOMER, {filters: [], searchInput: '', nbSearch: 0} as IFilterAndSearchPurchase);
          this.$store.dispatch(NavigationActionTypes.NAVIGATION_NAVIGATE_FORWARD, {
            label: RouterEnum.HOME_LABEL,
            name: RouterEnum.HOME_NAME,
            path: RouterEnum.HOME_PATH,
            canModifyData: false,
          } as INavigation);
        }
        else {
          this.showDialogChangePassword();
        }
      }
      this.isLoading = false;
    },
    onKeyPress(evt: KeyboardEvent) {
        if (evt.keyCode === 13) {
          this.login();
        }
    },
    showDialogChangePassword() {
      this.isDialogChangePasswordVisible = true;
    },
    hideDialogChangePassword() {
      this.isDialogChangePasswordVisible = false;
    },
    checkUser() {
      if (this.currentUser && this.currentUser.temporaryPassword === false) {
        this.$store.dispatch(NavigationActionTypes.NAVIGATION_NAVIGATE_FORWARD, {
            label: RouterEnum.HOME_LABEL,
            name: RouterEnum.HOME_NAME,
            path: RouterEnum.HOME_PATH,
            canModifyData: false,
          } as INavigation);
      }
    }
  },
  mounted() {
    this.$store.dispatch(NavigationActionTypes.NAVIGATION_RESET_NAVIGATION);
  }
});
