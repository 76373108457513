
import {DialogEvents} from "@/constantes/events";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { IUserChangePassword } from "@/types/IUser.interface";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
    name: "TheDialogChangePassword",
    props: {
        message: {
            type: String,
            required: true
        },
        textButtonValidation: {
            type: String,
            required: true
        },
        textButtonCancel: {
            type: String,
            required: false
        },
    },
    emits: [DialogEvents.DIALOG_CLOSE_CANCEL, DialogEvents.DIALOG_CLOSE_VALID],
    data() {
        return {
            oldPassword: "",
            password: "",
            confirmPassword: "",
            errorMessage: "",
        };
    },
    computed: {
        ...mapGetters("user", ["currentUser"]),
    },
    methods: {
        async validation() {
            this.errorMessage = "";
            const result = await this.$store.dispatch(UserActionTypes.USER_CHANGE_PASSWORD, {
                userId: this.currentUser.id,
                oldPassword: this.oldPassword,
                password: this.password,
                confirmPassword: this.confirmPassword,
            } as IUserChangePassword);
            if (!result.message) {
                this.$emit(DialogEvents.DIALOG_CLOSE_VALID);
            } else {
                this.errorMessage = String(result.message);
            }
        },
        cancel() {
            this.$emit(DialogEvents.DIALOG_CLOSE_CANCEL);
        }
    }
})
