
import { DialogEvents } from "@/constantes/events";
import { AdminActionTypes } from "@/store/modules/admin/action-types";
import { ClothingActionTypes } from "@/store/modules/clothing/action-types";
import { CustomerActionTypes } from "@/store/modules/customer/action-types";
import { MeasurementActionTypes } from "@/store/modules/measurement/action-types";
import { PurchaseActionTypes } from "@/store/modules/purchase/action-types";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { defineComponent } from "vue";
import { version } from "../../../package.json";
import TheLoader from "./TheLoader.vue";
import { mapGetters } from "vuex";
import NavigationRecap from "@/components/informations/NavigationRecap.vue";
import { NavigationActionTypes } from "@/store/modules/navigation/action-types";
import ErrorRecap from "../informations/ErrorRecap.vue";
import { ErrorCustomActionTypes } from "@/store/modules/errorCustom/action-types";
import { StockActionTypes } from "@/store/modules/stock/action-types";
import { AdjustmentActionTypes } from "@/store/modules/adjustment/action-types";
import { RouterEnum } from "@/router/router.enum";
import { INavigation } from "@/types/INavigation.interface";
import TheDialog from "./TheDialog.vue";
import { ColorActionTypes } from "@/store/modules/color/action-types";
import toolsService from "@/services/tools.service";

export interface IClearItem {
    id: number;
    name: string;
    isClearDone: boolean;
}

export default defineComponent({
  components: { TheLoader, NavigationRecap, ErrorRecap, TheDialog },
    name: "TheDialogInformations",
    emits: [DialogEvents.DIALOG_CLOSE_VALID],
    data() {
        return {
            appVersion: version,
            isResetDone: "non",
            errorMessage: "",
            tabClearDone: [] as IClearItem[],
            displayDetailsReset: false,
            isConfirmResetVisible: false,
            isTouchNeeded: false,
        };
    },
    computed: {
        ...mapGetters("admin", ["backendVersion"])
    },
    methods: {
        checkIsTouchNeeded() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|safari|Opera Mini/i.test(navigator.userAgent)) {
                this.isTouchNeeded = true;
            } else {
                this.isTouchNeeded = false;
            }
            
        },
        validation() {
            this.$emit(DialogEvents.DIALOG_CLOSE_VALID);
        },
        initTabClear() {
            this.tabClearDone = [
            {
                id: 1,
                name: "Clothing",
                isClearDone: false,
            },
            {
                id: 2,
                name: "Admin",
                isClearDone: false,
            },
            {
                id: 3,
                name: "Customer",
                isClearDone: false,
            },
            {
                id: 4,
                name: "Measurement",
                isClearDone: false,
            },
            {
                id: 5,
                name: "Purchase",
                isClearDone: false,
            },
            {
                id: 6,
                name: "User",
                isClearDone: false,
            },
            {
                id: 7,
                name: "Navigation",
                isClearDone: false,
            },
            {
                id: 8,
                name: "Stock",
                isClearDone: false,
            },
            {
                id: 9,
                name: "Color",
                isClearDone: false,
            },
            {
                id: 10,
                name: "Adjustment",
                isClearDone: false,
            },
            {
                id: 11,
                name: "Errors",
                isClearDone: false,
            }
            ] as IClearItem[];
        },
        confirmReset() {
            this.isConfirmResetVisible = true;
        },
        hideConfirmReset() {
            this.isConfirmResetVisible = false;
        },
        async reset() {
            this.hideConfirmReset();
            this.isResetDone = "En cours";
            this.displayDetailsReset = true;
            this.errorMessage = "";
            //this.tabClearDone = [] as IClearItem[];
            this.initTabClear();

            try {
                this.$store.dispatch(PurchaseActionTypes.PURCHASE_INIT_PURCHASE_FILTERS);
                this.$store.dispatch(AdjustmentActionTypes.ADJUSTMENT_INIT_PURCHASE_ADJUSTMENT_FILTERS);
                await this.$store.dispatch(ClothingActionTypes.CLOTHING_RESET_CLOTHING);
                this.tabClearDone[0].isClearDone = true;
                await this.$store.dispatch(AdminActionTypes.ADMIN_RESET_ADMIN);
                this.tabClearDone[1].isClearDone = true;
                await this.$store.dispatch(CustomerActionTypes.CUSTOMER_RESET_CUSTOMER);
                this.tabClearDone[2].isClearDone = true;
                await this.$store.dispatch(MeasurementActionTypes.MEASUREMENT_RESET_MEASUREMENT);
                this.tabClearDone[3].isClearDone = true;
                await this.$store.dispatch(PurchaseActionTypes.PURCHASE_RESET_PURCHASE);
                this.tabClearDone[4].isClearDone = true;
                await this.$store.dispatch(UserActionTypes.USER_RESET_USERS);
                this.tabClearDone[5].isClearDone = true;
                this.$store.dispatch(NavigationActionTypes.NAVIGATION_RESET_NAVIGATION);
                this.tabClearDone[6].isClearDone = true;
                await this.$store.dispatch(StockActionTypes.STOCK_RESET_STOCKS);
                this.tabClearDone[7].isClearDone = true;
                await this.$store.dispatch(ColorActionTypes.COLOR_RESET_COLORS);
                this.tabClearDone[8].isClearDone = true;
                await this.$store.dispatch(AdjustmentActionTypes.ADJUSTMENT_RESET_ADJUSTMENTS);
                this.tabClearDone[9].isClearDone = true;
                this.$store.dispatch(ErrorCustomActionTypes.ERROR_CUSTOM_CLEAR_ERRORS);
                this.tabClearDone[10].isClearDone = true;
                this.isResetDone = "Terminé";
                this.displayDetailsReset = false;
                // logout
                this.$store.dispatch(UserActionTypes.USER_LOGOUT);
                this.$store.dispatch(NavigationActionTypes.NAVIGATION_NAVIGATE_BACKWARD, {
                    label: RouterEnum.LOGIN_LABEL,
                    name: RouterEnum.LOGIN_NAME,
                    path: RouterEnum.LOGIN_PATH,
                    canModifyData: false,
                } as INavigation);
                this.validation();
            } catch(error) {
                if (error && (error as Error).message && (error as Error).message !== "") {
                    this.errorMessage = (error as Error).message;
                } else {
                    this.errorMessage = String(error);
                }
                
                this.isResetDone = "Terminé en erreur";
            }
        }
    },
    mounted() {
        this.$store.dispatch(AdminActionTypes.ADMIN_GET_BACKEND_VERSION);
    }
})
