export enum ClothingActionTypes {
    LOAD_CLOTHING_REF = "LOAD_CLOTHING_REF",
    SET_ACTIVE_CLOTHING_DETAILS = "SET_ACTIVE_CLOTHING_DETAILS",
    ADD_TO_CART = "ADD_TO_CART",
    REMOVE_FROM_CART = "REMOVE_FROM_CART",
    UPDATE_ORDER_IN_CART = "UPDATE_ORDER_IN_CART",
    SET_ACTIVE_ITEM_ID = "SET_ACTIVE_ITEM_ID",
    SET_USER_ON_CART = "SET_USER_ON_CART",
    SET_CUSTOMER_ON_CART = "SET_CUSTOMER_ON_CART",
    CREATE_NEW_ORDER = "CREATE_NEW_ORDER",
    SET_ACTIVE_CART = "SET_ACTIVE_CART",
    DELETE_CART = "DELETE_CART",
    RESET_CLOTHING = "RESET_CLOTHING",

    CLOTHING_LOAD_CLOTHING_REF = "clothing/LOAD_CLOTHING_REF",
    CLOTHING_SET_ACTIVE_CLOTHING_DETAILS = "clothing/SET_ACTIVE_CLOTHING_DETAILS",
    CLOTHING_ADD_TO_CART = "clothing/ADD_TO_CART",
    CLOTHING_REMOVE_FROM_CART = "clothing/REMOVE_FROM_CART",
    CLOTHING_UPDATE_ORDER_IN_CART = "clothing/UPDATE_ORDER_IN_CART",
    CLOTHING_SET_ACTIVE_ITEM_ID = "clothing/SET_ACTIVE_ITEM_ID",
    CLOTHING_SET_USER_ON_CART = "clothing/SET_USER_ON_CART",
    CLOTHING_SET_CUSTOMER_ON_CART = "clothing/SET_CUSTOMER_ON_CART",
    CLOTHING_CREATE_NEW_ORDER = "clothing/CREATE_NEW_ORDER",
    CLOTHING_SET_ACTIVE_CART = "clothing/SET_ACTIVE_CART",
    CLOTHING_DELETE_CART = "clothing/DELETE_CART",
    CLOTHING_RESET_CLOTHING = "clothing/RESET_CLOTHING",
}