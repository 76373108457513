import { MutationTree } from "vuex";

import { State } from "./state";
import { PurchaseMutationTypes as MutationTypes } from "./mutation-types";
import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase, IPurchaseCommentsPayload, IPurchaseDetail } from "@/types/IPurchase.interface";
import { IUser } from "@/types/IUser.interface";
import { ICustomer } from "@/types/ICustomer.interface";

export type Mutations<S = State> = {
    [MutationTypes.ADD_ITEM_TO_PURCHASE](state: S, payload: IPurchaseDetail): void;
    [MutationTypes.REMOVE_ITEM_FROM_PURCHASE](state: S, payload: number): void;
    [MutationTypes.SET_USER_ON_PURCHASE](state: S, payload: IUser): void;
    [MutationTypes.SET_CUSTOMER_ON_PURCHASE](state: S, payload: ICustomer): void;
    //[MutationTypes.CREATE_NEW_PURCHASE](state: S, payload: IPurchase): void;
    [MutationTypes.SET_ACTIVE_PURCHASE](state: S, payload: IPurchase): void;
    [MutationTypes.SET_ACTIVE_PURCHASE_DETAIL](state: S, payload: IPurchaseDetail): void;
    //[MutationTypes.DELETE_PURCHASE](state: S, payload: IPurchase): void;
    //[MutationTypes.LOAD_PURCHASES](state: S, payload: IPurchase[]): void;
    //[MutationTypes.ADD_PURCHASE](state: S, purchase: IPurchase): void;
    [MutationTypes.TOGGLE_DISPLAY_IMAGE](state: S): void;
    [MutationTypes.INIT_PURCHASE_FILTERS](state: S, payload: IFilterPurchase[]): void;
    [MutationTypes.SET_FILTER_ACTIVE](state: S, payload: boolean): void;
    [MutationTypes.SET_ACTION_IN_PROGRESS](state: S, payload: boolean): void;
    [MutationTypes.RESET_PURCHASE](state: S): void;
    [MutationTypes.SET_STATUS_PURCHASE_UPDATED](state: S): void;
    [MutationTypes.SET_FILTER_AND_SEARCH_PURCHASE](state: State, payload: IFilterAndSearchPurchase): void;
    [MutationTypes.UPDATE_PURCHASE_COMMENTS](state: S, payload: IPurchaseCommentsPayload): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.ADD_ITEM_TO_PURCHASE](state: State, item: IPurchaseDetail) {
        let id = 0;
        for (const p of state.activePurchase.purchaseDetails) {
            if (p.id > id) {
                id = p.id;
            }
        }
        item.id = id + 1;
        // for (const iterator of object) {
            
        // }
        state.activePurchase.purchaseDetails.push(item);
        //console.log("ADD_ITEM_TO_PURCHASE activePurchase => ", state.activePurchase);
        //state.purchases = [...state.purchases];
    },
    [MutationTypes.REMOVE_ITEM_FROM_PURCHASE](state: State, id: number) {
        //console.log("MutationTypes.REMOVE_ITEM_FROM_PURCHASE", state.activePurchase.purchaseDetails, id);
        state.activePurchase.purchaseDetails.filter((item, index, arr) => {
            if (item.id === id) {
                arr.splice(index, 1);
                return false;
            } else {
                return true;
            }
        });
        //state.purchases = [...state.purchases];
    },
    [MutationTypes.SET_USER_ON_PURCHASE](state: State, user: IUser) {
        state.activePurchase.userId = user.id;
    },
    [MutationTypes.SET_CUSTOMER_ON_PURCHASE](state: State, customer: ICustomer) {
        state.activePurchase.customerId = customer.id;
    },
    // [MutationTypes.CREATE_NEW_PURCHASE](state: State, purchase: IPurchase) {
    //     state.purchases.push(purchase);
    //     state.purchases = [...state.purchases];
    // },
    [MutationTypes.SET_ACTIVE_PURCHASE](state: State, purchase: IPurchase) {
        state.activePurchase = purchase;
    },
    [MutationTypes.SET_ACTIVE_PURCHASE_DETAIL](state: State, purchaseDetail: IPurchaseDetail) {
        state.activePurchaseDetail = purchaseDetail;
    },
    // [MutationTypes.DELETE_PURCHASE](state: State, purchase: IPurchase) {
    //     state.purchases.filter((item, index, arr) => {
    //         if (item.id === purchase.id) {
    //             arr.splice(index, 1);
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     });
    //     state.purchases = [...state.purchases];
    // },
    // [MutationTypes.LOAD_PURCHASES](state: State, purchases: IPurchase[]) {
    //     state.purchases = [...purchases];
    // },
    // [MutationTypes.ADD_PURCHASE](state: State, purchase: IPurchase) {
    //     state.purchases.push(purchase);
    //     state.purchases = [...state.purchases];
    // },
    [MutationTypes.TOGGLE_DISPLAY_IMAGE](state: State) {
        const value = state.displayImages;
        state.displayImages = !value;
    },
    [MutationTypes.INIT_PURCHASE_FILTERS](state: State, payload: IFilterPurchase[]) {
        state.purchaseFilters = [...payload];
    },
    [MutationTypes.SET_FILTER_ACTIVE](state: State, payload: boolean) {
        state.isFilterActive = payload;
    },
    [MutationTypes.SET_ACTION_IN_PROGRESS](state: State, payload: boolean) {
        state.actionInProgress = payload;
    },
    [MutationTypes.SET_STATUS_PURCHASE_UPDATED](state: State) {
        console.log('here');
        state.statusPurchaseUpdated = state.statusPurchaseUpdated + 1;
    },
    [MutationTypes.RESET_PURCHASE](state: State) {
        state.activePurchase = {
            id: 0,
            cause: "",
            productionDate: "",
            fittingDate: "",
            deadlineDate: "",
            customerId: 0,
            userId: 0,
            shopId: 0,
        } as IPurchase;
        state.activePurchaseDetail = {} as IPurchaseDetail;
        //state.purchases = [] as IPurchase[];
        state.displayImages = true;
        state.isFilterActive = false;
        state.purchaseFilters = [] as IFilterPurchase[];
        state.actionInProgress = false;
        state.statusPurchaseUpdated = 0;
    },
    [MutationTypes.SET_FILTER_AND_SEARCH_PURCHASE](state: State, payload: IFilterAndSearchPurchase) {
        console.log("SET_FILTER_AND_SEARCH_PURCHASE", payload);
        state.filterAndSearchPurchase = payload;
    },
    [MutationTypes.UPDATE_PURCHASE_COMMENTS](state: State, payload: IPurchaseCommentsPayload) {
        if (state.activePurchase && state.activePurchase.id === payload.purchaseId) {
            state.activePurchase.comments = payload.comments;
        }
    }
}