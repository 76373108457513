export enum AdjustmentMutationTypes {
    SET_ACTIVE_ADJUSTMENT = "SET_ACTIVE_ADJUSTMENT",
    SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID = "SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID",
    LOAD_ADJUSTMENTS = "LOAD_ADJUSTMENTS",
    SET_FILTERED_PURCHASES_ADJUSTMENT = "SET_FILTERED_PURCHASES_ADJUSTMENT",
    INIT_PURCHASE_ADJUSTMENT_FILTERS = "INIT_PURCHASE_ADJUSTMENT_FILTERS",
    SET_FILTER_ACTIVE = "SET_FILTER_ACTIVE",
    LOAD_ADJUSTMENTS_FROM_PURCHASE_ID = "LOAD_ADJUSTMENTS_FROM_PURCHASE_ID",
    SET_FILTER_AND_SEARCH_ADJUSTMENT = 'SET_FILTER_AND_SEARCH_ADJUSTMENT',
    UPDATE_ADJUSTMENT_COMMENTS = "UPDATE_ADJUSTMENT_COMMENTS",
}