import { IShop } from "@/types/IShop.interface"
import { IStock } from "@/types/IStock"

export type State = {
    stocks: IStock[],
    lastUpdate: Date | undefined,
    isUploadDone: boolean
}

export const state: State = {
    stocks: [],
    lastUpdate: undefined,
    isUploadDone: false
}