
import { defineComponent } from "vue";
import {version} from "../../../package.json";
import TheDialogInformations from "./TheDialogInformations.vue";

export default defineComponent({
    name: "TheInformations",
    components: {
        TheDialogInformations
    },
    data() {
        return {
            appVersion: version,
            displayDialogInformations: false
        }
    },
    methods: {
        showInformations() {
            this.displayDialogInformations = true;
        },
        hideInformations() {
            this.displayDialogInformations = false;
        }
        
    }
    
})
