export enum ClothingEvents{
    CLOTHING_OPTION_UPDATED_EVENT = "clothingoptionupdatedevent",
    CLOTHING_TYPE_UPDATED_EVENT= "clothingtypeupdatedevent",
}

export enum PurchaseEvents {
    PURCHASE_CLOTHING_TYPE_UPDATED_EVENT = "purchaseclothingtypeupdatedevent",
    PURCHASE_DETAIL_ITEM_UPDATED_EVENT = "purchasedetailitemupdatedevent",
    PURCHASE_SELECT_ITEM_EVENT = "purchaseselectitemevent",
    PURCHASE_DISPLAY_HIDE_EVENT = "purchasedisplayhideevent",
    PURCHASE_PRESET_ITEM_EVENT = "purchasepresetitemevent",
    PURCHASE_CLEAR_PRODUCT = "purchaseclearproduct",
}

export enum DialogEvents {
    DIALOG_CLOSE_VALID = "dialogclosevalid",
    DIALOG_CLOSE_CANCEL = "dialogclosecancel",
    DIALOG_SELECT_ITEM = "dialogselectitem",
}

export enum MeasurementEvents {
    MEASUREMENT_ITEM_UPDATED_EVENT = "measurementitemupdatedevent",
    MEASUREMENT_PANT_SIZE_CHANGE = "measurementpantsizechange",
    MEASUREMENT_VESTE_SIZE_CHANGE = "measurementvestesizechange",
    MEASUREMENT_VESTE_CHANGE = "measurementvestechange",
}

export enum AdminEvents {
    ADMIN_NEW_SHOP_EVENT = "adminnewshopevent",
    ADMIN_NEW_SELLER_EVENT = "adminnewsellerevent",
    ADMIN_NEW_SUPPLY_CHAIN_EVENT = "adminnewsupplychainevent",
    ADMIN_SELECT_SHOP_EVENT = "adminselectshopevent",
    ADMIN_SELECT_SUPPLY_CHAIN_EVENT = "adminselectsupplychainevent",
    ADMIN_SELECT_SELLER_EVENT = "adminselectsellerevent",
    ADMIN_SELECT_USER_EVENT = "adminselectuserevent",
    ADMIN_SELECT_UPLOAD_FILE_STOCK_EVENT = "adminselectuploadfilestockevent",
    ADMIN_EXIT_EVENT = "adminexitevent",
}

export enum AdjustmentEvents {
    ADJUSTMENT_SELECT_TYPE_EVENT = "adjustmentselecttypeevent",
}

export enum PdfEvents {
    PDF_GENERATED = "pdfgenerated",
}
