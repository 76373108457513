import { GetterTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { IColor } from "@/types/IColor";

export type Getters = {
    colors(state: State): IColor[];
}

export const getters: GetterTree<State, RootState> & Getters = {
    colors: (state) => state.colors,
}