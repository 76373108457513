import { IClothing, IClothingDetails, IClothingType } from "@/types/IClothing.interface"
import { IIhmCart, IIhmOrder } from "@/types/IIhmOrder.interface"
import { IUser } from "@/types/IUser.interface"

export type State = {
    clothings: IClothing[],
    clothingDetails: IClothingDetails[],
    activeClothingDetails?: IClothingDetails,
    activeCart: IIhmCart,
    activeItemId: number,
    carts: IIhmCart[],
    clothingTypes: IClothingType[],
}

export const state: State = {
    clothings: [],
    clothingDetails: [],
    activeClothingDetails: undefined,
    activeCart: {
        id: 0,
        user: {} as IUser,
        customer: null,
        orders: [] as IIhmOrder[]
    } as IIhmCart,
    activeItemId: 0,
    carts: [] as IIhmCart[],
    clothingTypes: [] as IClothingType[],
}