import { IShop } from "@/types/IShop.interface"

export type State = {
    adminMenuActif: boolean,
    shops: IShop[],
    backendVersion: string
}

export const state: State = {
    adminMenuActif: false,
    shops: [],
    backendVersion: ""
}