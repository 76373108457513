export enum StockActionTypes {
    LOAD_STOCKS = "LOAD_STOCKS",
    RESET_STOCKS = "RESET_STOCKS",
    UPDATE_STOCKS = "UPDATE_STOCKS",
    SET_IS_UPLOAD_DONE = "SET_IS_UPLOAD_DONE",
    
    STOCK_LOAD_STOCKS = "stock/LOAD_STOCKS",
    STOCK_RESET_STOCKS = "stock/RESET_STOCKS",
    STOCK_UPDATE_STOCKS = "stock/UPDATE_STOCKS",
    STOCK_SET_IS_UPLOAD_DONE = "stock/SET_IS_UPLOAD_DONE",
}   