export enum PurchaseActionTypes {
    ADD_ITEM_TO_PURCHASE = "ADD_TO_PURCHASE",
    UPDATE_ITEM_IN_PURCHASE = "UPDATE_ITEM_IN_PURCHASE",
    REMOVE_ITEM_FROM_PURCHASE = "REMOVE_FROM_PURCHASE",
    SET_USER_ON_PURCHASE = "SET_USER_ON_PURCHASE",
    SET_CUSTOMER_ON_PURCHASE = "SET_CUSTOMER_ON_PURCHASE",
    CREATE_NEW_PURCHASE = "CREATE_NEW_PURCHASE",
    CREATE_NEW_PURCHASE_ORDER_AGAIN = "CREATE_NEW_PURCHASE_ORDER_AGAIN",
    SET_ACTIVE_PURCHASE = "SET_ACTIVE_PURCHASE",
    SET_ACTIVE_PURCHASE_DETAIL = "SET_ACTIVE_PURCHASE_DETAIL",
    DELETE_PURCHASE = "DELETE_PURCHASE",
    LOAD_PURCHASES = "LOAD_PURCHASES",
    SAVE_PURCHASE = "SAVE_PURCHASE",
    RESET_PURCHASE = "RESET_PURCHASE",
    TOGGLE_DISPLAY_IMAGES = "TOGGLE_DISPLAY_IMAGES",
    SET_FILTERED_PURCHASES = "SET_FILTERED_PURCHASES",
    INIT_PURCHASE_FILTERS = "INIT_PURCHASE_FILTERS",
    SET_PURCHASE_STATUS = "SET_PURCHASE_STATUS",
    RECEIVE_PURCHASE = "RECEIVE_PURCHASE",
    FETCH_PURCHASE_DETAILS = "FETCH_PURCHASE_DETAILS",
    UPDATE_PURCHASE_COMMENTS = "UPDATE_PURCHASE_COMMENTS",
    

    PURCHASE_ADD_ITEM_TO_PURCHASE = "purchase/ADD_TO_PURCHASE",
    PURCHASE_UPDATE_ITEM_IN_PURCHASE = "purchase/UPDATE_ITEM_IN_PURCHASE",
    PURCHASE_REMOVE_ITEM_FROM_PURCHASE = "purchase/REMOVE_FROM_PURCHASE",
    PURCHASE_SET_USER_ON_PURCHASE = "purchase/SET_USER_ON_PURCHASE",
    PURCHASE_SET_CUSTOMER_ON_PURCHASE = "purchase/SET_CUSTOMER_ON_PURCHASE",
    PURCHASE_CREATE_NEW_PURCHASE = "purchase/CREATE_NEW_PURCHASE",
    PURCHASE_CREATE_NEW_PURCHASE_ORDER_AGAIN = "purchase/CREATE_NEW_PURCHASE_ORDER_AGAIN",
    PURCHASE_SET_ACTIVE_PURCHASE = "purchase/SET_ACTIVE_PURCHASE",
    PURCHASE_SET_ACTIVE_PURCHASE_DETAIL = "purchase/SET_ACTIVE_PURCHASE_DETAIL",
    PURCHASE_DELETE_PURCHASE = "purchase/DELETE_PURCHASE",
    PURCHASE_LOAD_PURCHASES = "purchase/LOAD_PURCHASES",
    PURCHASE_SAVE_PURCHASE = "purchase/SAVE_PURCHASE",
    PURCHASE_RESET_PURCHASE = "purchase/RESET_PURCHASE",
    PURCHASE_TOGGLE_DISPLAY_IMAGES = "purchase/TOGGLE_DISPLAY_IMAGES",
    PURCHASE_SET_FILTERED_PURCHASES = "purchase/SET_FILTERED_PURCHASES",
    PURCHASE_INIT_PURCHASE_FILTERS = "purchase/INIT_PURCHASE_FILTERS",
    PURCHASE_SET_PURCHASE_STATUS = "purchase/SET_PURCHASE_STATUS",
    PURCHASE_RECEIVE_PURCHASE = "purchase/RECEIVE_PURCHASE",
    PURCHASE_FETCH_PURCHASE_DETAILS = "purchase/FETCH_PURCHASE_DETAILS",
    PURCHASE_UPDATE_PURCHASE_COMMENTS = "purchase/UPDATE_PURCHASE_COMMENTS",
}