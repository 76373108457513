import { ActionTree, ActionContext } from "vuex";

import { IUser, IUserChangePassword } from "@/types/IUser.interface";

import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { UserMutationTypes } from "./mutation-types";
import { UserActionTypes } from "./action-types";

import userService from "@/services/user.service";

//import router from '@/router';
import { IShop } from "@/types/IShop.interface";
import { IErrorRessource } from "@/types/IErrorCustom";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [UserActionTypes.LOGIN]({ commit }: AugmentedActionContext, payload: IUser): Promise<boolean>;
    [UserActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void;
    [UserActionTypes.SET_CURRENT_SHOP]({ commit } : AugmentedActionContext, payload: IShop): Promise<void>;
    [UserActionTypes.LOAD_USERS](augmentedActionContext: AugmentedActionContext, payload: string): Promise<void>;
    [UserActionTypes.CREATE_USER](augmentedActionContext: AugmentedActionContext, payload: IUser): Promise<void>;
    [UserActionTypes.UPDATE_USER]({ commit }: AugmentedActionContext, payload: IUser): Promise<void>;
    [UserActionTypes.RESET_USERS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [UserActionTypes.CHANGE_PASSWORD](augmentedActionContext: AugmentedActionContext, payload: IUserChangePassword): Promise<IUser | IErrorRessource>;
    [UserActionTypes.RESET_PASSWORD](augmentedActionContext: AugmentedActionContext, payload: number): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [UserActionTypes.LOGIN]({commit}, payload: IUser): Promise<boolean> {
        try {
            const loginInfos = await userService.userLogin(payload);
            //console.log("loginInfos => ", loginInfos);
            if (loginInfos.statusCode && loginInfos.statusCode !== 200) {
                //console.log("loginInfos", loginInfos);
                commit(UserMutationTypes.SET_ERROR_LOGIN, loginInfos.message);
                return false;   
            } else {
                commit(UserMutationTypes.SET_TOKEN, loginInfos.accessToken);

                const user = await userService.getMe();
                //console.log("USER => ", user);

                commit(UserMutationTypes.SET_USER, user);
                commit(UserMutationTypes.SET_ERROR_LOGIN, "");
                // if (user.userToShops && user.userToShops.length > 0) {
                //     for (const uts of user.userToShops) {
                //         if (uts.isActive) {
                //             //console.log("Action set shop => ", uts.shop);
                //             commit(UserMutationTypes.SET_SHOP, uts.shop);
                //             break;
                //         }
                //     }
                // }
                //console.log("Action set shops => ", user.userToShops);
                commit(UserMutationTypes.SET_SHOPS, user.userToShops);
                
                const users = await userService.getUsers();
                commit(UserMutationTypes.SET_SHOP_USERS, users);
                // router.push("/home");
                return true;
            }
        } catch (error) {
            if (error && (error as Error).message && (error as Error).message !== "") {
                commit(UserMutationTypes.SET_ERROR_LOGIN, error as string);    
            } else {
                commit(UserMutationTypes.SET_ERROR_LOGIN, error as string);
            }
            
            return false;
        }
    },
    [UserActionTypes.LOGOUT]({commit}): void {
        commit(UserMutationTypes.SET_USER, {} as IUser);
        commit(UserMutationTypes.SET_TOKEN, "");
        commit(UserMutationTypes.SET_SHOP, {
            name: ""
        } as IShop);
        //router.push("/");
    },
    async [UserActionTypes.SET_CURRENT_SHOP]({ commit }, payload: IShop): Promise<void> {
        commit(UserMutationTypes.SET_SHOP, payload);
    },
    async [UserActionTypes.LOAD_USERS](augmentedActionContext: AugmentedActionContext): Promise<void> {
        await loadUsers(augmentedActionContext);
    },
    async [UserActionTypes.CREATE_USER](augmentedActionContext: AugmentedActionContext, payload: IUser): Promise<void> {
        const user = await userService.createUser(payload);
        //commit(UserMutationTypes.ADD_USER, user);
        await loadUsers(augmentedActionContext);
    },
    async [UserActionTypes.UPDATE_USER]({ commit }, payload: IUser): Promise<void> {
        await userService.updateUser(payload);
        commit(UserMutationTypes.DELETE_USERS, undefined);
        const users = await userService.getUsers();
        commit(UserMutationTypes.LOAD_USERS, users);
    },
    async [UserActionTypes.CHANGE_PASSWORD](augmentedActionContext: AugmentedActionContext, payload: IUserChangePassword): Promise<IUser | IErrorRessource> {
        const result = await userService.changePassword(payload);
        const user = await userService.getMe();

        augmentedActionContext.commit(UserMutationTypes.SET_USER, user);
        return result;
    },
    async [UserActionTypes.RESET_USERS](augmentedActionContext: AugmentedActionContext): Promise<void> {
        // augmentedActionContext.commit(UserMutationTypes.LOAD_USERS, [] as IUser[]);
        // await loadUsers(augmentedActionContext);
        augmentedActionContext.commit(UserMutationTypes.RESET_USERS, undefined);
    },
    async [UserActionTypes.RESET_PASSWORD](augmentedActionContext: AugmentedActionContext, payload: number): Promise<boolean> {
        return userService.resetPassword(payload);
    }
}

async function loadUsers(augmentedActionContext: AugmentedActionContext): Promise<void> {
    const users = await userService.getUsers();
    augmentedActionContext.commit(UserMutationTypes.LOAD_USERS, users);
}