import { GetterTree } from "vuex";

import { ITypeUser, IUser, IUserToShop } from "@/types/IUser.interface";

import { RootState } from "@/store";

import { State } from "./state";
import constantes, { RoleUserEnum, TypeUserEnum } from "@/constantes/constantes";
import { IShop } from "@/types/IShop.interface";

export type Getters = {
    currentUser(state: State): IUser;
    isAdmin(state: State): boolean;
    isChainSupply(state: State): boolean;
    currentShopName(state: State): string;
    errorLogin(state: State): string;
    shopUsers(state: State): IUser[];
    token(state: State): string;
    shops(state: State): IUserToShop[];
    currentShop(state: State): IShop;
    users(state: State): IUser[];
    supplyChainUsers(state: State): IUser[];
    sellerUsers(state: State): IUser[];
}

export const getters: GetterTree<State, RootState> & Getters = {
    currentUser: (state) => state.currentUser,
    isAdmin: (state) => {
        if (state.currentUser && state.currentUser.typeUser && state.currentUser.typeUser === constantes.TYPE_USER_ADMIN) {
            return true;
        } else {
            return false;
        }
    },
    isChainSupply: (state) => {
        if (state.currentUser && state.currentUser.typeUser && state.currentUser.typeUser === constantes.TYPE_CHAIN_SUPPLY) {
            return true;
        } else {
            return false;
        }
    },
    currentShopName: (state) => {
        if(state.currentShop) {
            return state.currentShop.name;
        } else {
            return "";
        }
    },
    errorLogin: (state) => state.errorLogin,
    shopUsers: (state) => state.shopUsers,
    token: (state) => state.token,
    shops: (state) => state.shops,
    currentShop: (state) => state.currentShop,
    users: (state) => state.users,
    supplyChainUsers: (state) => {
        return state.users.filter(u => u.active && u.typeUser === TypeUserEnum.CHAIN_SUPPLY);
    },
    sellerUsers: (state) => {
        return state.users.filter(u => {
            if (u.active && u.userToShops) {
                let result = false;
                for (const uts of u.userToShops) {
                    if (uts.isActive && uts.role === RoleUserEnum.VENDOR) {
                        result = true;
                    } 
                }
                return result;
            }
        });
    },

}