import constantes from "@/constantes/constantes";
import { ICustomer, ICustomerReferential, ICustomerToMeasurement, IMarketingInformation } from "@/types/ICustomer.interface";
import toolsService from "./tools.service";

const apiUrlCustomer = toolsService.getApiUrl() + "v1/customer";

export default {
    getCustomers(): Promise<ICustomer[]> {
        if (toolsService.isUserConnected() && toolsService.isShopSelected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(apiUrlCustomer, requestOptions, {}, "customer.service.ts", "getCustomers");
        } else {
            return new Promise(resolve => resolve([] as ICustomer[])); 
        }
    },
    searchCustomer(searchInput: string): Promise<ICustomer[]> {
        if (toolsService.isUserConnected() && toolsService.isShopSelected()) {
            const requestOptions = toolsService.getRequestOption("POST", JSON.stringify({body: searchInput}));
            return toolsService.fetchRessource(apiUrlCustomer + "/search", requestOptions, {}, "customer.service.ts", "getCustomers");
        } else {
            return new Promise(resolve => resolve([] as ICustomer[])); 
        }
    },
    getCustomer(customer: ICustomer): Promise<ICustomer> {
        const requestOptions = toolsService.getRequestOption("GET", "");
        return toolsService.fetchRessource(apiUrlCustomer + "/" + customer.id, requestOptions, customer, "customer.service.ts", "getCustomer");
    },
    getCustomerFromId(customerId: number): Promise<ICustomer> {
        const requestOptions = toolsService.getRequestOption("GET", "");
        return toolsService.fetchRessource(apiUrlCustomer + "/" + customerId, requestOptions, customerId, "customer.service.ts", "getCustomerFromId");
    },

    createCustomer(customer: ICustomer): Promise<ICustomer> {
        const _customer = formatCustomer(customer);
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(_customer));
        return toolsService.fetchRessource(apiUrlCustomer, requestOptions, customer, "customer.service.ts", "createCustomer");
    },
    async updateCustomer(customer: ICustomer): Promise<ICustomer> {
        const _customer = formatCustomerForUpdate(customer);

        if (!(_customer as any).customerToMeasurements || (_customer as any).customerToMeasurements.length === 0) {
            const _c =  await this.getCustomerFromId(customer.id);
            if (_c.customerToMeasurements.length > 0) {
                throw new Error("Un problème est survenu, merci de recharger votre application.");
            }
        }

        const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(_customer));
        return toolsService.fetchRessource(apiUrlCustomer + "/" + customer.id, requestOptions, customer, "customer.service.ts", "updateCustomer");
    },
    deleteCustomer(customer: ICustomer): Promise<void> {
        const requestOptions = toolsService.getRequestOption("DELETE", "");
        return toolsService.fetchRessource(apiUrlCustomer + "/" + customer.id, requestOptions, customer, "customer.service.ts", "deleteCustomer");
    },
    getReferentialHeight(): Promise<ICustomerReferential[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/referential/height", requestOptions, {}, "customer.service.ts", "getReferentielHeight");
        } else {
            return new Promise(resolve => resolve([] as ICustomerReferential[]));
        }
    },
    async getReferentialWeight(): Promise<ICustomerReferential[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/referential/weight", requestOptions, {}, "customer.service.ts", "getReferentielWeight");
        } else {
            return new Promise(resolve => resolve([] as ICustomerReferential[]));
        }
    },
    async getReferentialShoesSize(): Promise<ICustomerReferential[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/referential/shoe-size", requestOptions, {}, "customer.service.ts", "getReferentielShoesSize");
        } else {
            return new Promise(resolve => resolve([] as ICustomerReferential[]));
        }
    },
    async getReferentielOrigin(): Promise<ICustomerReferential[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/referential/origin", requestOptions, {}, "customer.service.ts", "getReferentielOrigin");
        } else {
            return new Promise(resolve => resolve([] as ICustomerReferential[]));
        }
    },
    async getReferentielMotivation(): Promise<ICustomerReferential[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/referential/motivation", requestOptions, {}, "customer.service.ts", "getReferentielMotivation");
        } else {
            return new Promise(resolve => resolve([] as ICustomerReferential[]));
        }
    },
}

function formatCustomer(customer: ICustomer): unknown {
    const _customer: unknown = {
        id: customer.id,
        firstname: customer.firstname,
        lastname: customer.lastname,
        weight: parseInt(String(customer.weight)),
        size: parseInt(String(customer.size)),
        footSize: customer.footSize ? parseInt(String(customer.footSize)) : 0,
        phoneCode: "+33",
        phoneNumber: String(customer.phoneNumber).length === 9?  "0" + String(customer.phoneNumber): String(customer.phoneNumber),
        email: customer.email,
        profession: "",
        address: customer.address ? customer.address : "",
        city: customer.city ? customer.city : "",
        zipcode: customer.zipcode ? String(customer.zipcode) : "",
        country: "France",
        comments: "",
        birthdate: customer.birthdate ? customer.birthdate : "",
        customerToMeasurements: customer.customerToMeasurements ? customer.customerToMeasurements : [],
        stylist: customer.stylist,
        shopId: customer.shopId,
        userId: customer.userId,
        origins: JSON.stringify(customer.origins),//JSON.parse(formatMarketingInformationForService(customer.origins)),
        motivations: JSON.stringify(customer.motivations),//JSON.parse(formatMarketingInformationForService(customer.motivations)),
    };
    return _customer;
}

function formatCustomerForUpdate(customer: ICustomer): unknown {
    const _customer: unknown = {
        id: customer.id,
        firstname: customer.firstname,
        lastname: customer.lastname,
        weight: parseInt(String(customer.weight)),
        size: parseInt(String(customer.size)),
        footSize: customer.footSize ? parseInt(String(customer.footSize)) : 0,
        phoneCode: String(customer.phoneCode),
        phoneNumber: String(customer.phoneNumber),
        email: customer.email,
        profession: "",
        address: customer.address ? customer.address : "",
        city: customer.city ? customer.city : "",
        zipcode: customer.zipcode ? String(customer.zipcode) : "",
        country: "France",
        comments: "",
        birthdate: customer.birthdate ? customer.birthdate : "",
        customerToMeasurements: getCustomerToMeasurements(customer.customerToMeasurements),
        stylist: customer.stylist,
        shopId: customer.shopId,
        userId: customer.userId,
        userMeasurementId: customer.userMeasurementId ? customer.userMeasurementId : customer.userId,
        origins: JSON.stringify(customer.origins),//JSON.parse(formatMarketingInformationForService(customer.origins)),
        motivations: JSON.stringify(customer.motivations),//JSON.parse(formatMarketingInformationForService(customer.motivations)),
    };
    return _customer;
}

function getCustomerToMeasurements(payload: ICustomerToMeasurement[]): ICustomerToMeasurement[] {
    const result = [] as ICustomerToMeasurement[];
    if (payload) {
        for (const item of payload) {
            if (!result.find(ctm => ctm.measurementId === item.measurementId)) {
                if (item.valueFinale) {
                    result.push(item);
                }
            }
        }
    }
    return result;
}

function formatMarketingInformationForService(information: IMarketingInformation): string {
    let _result = '{';
    for (const item of information.datas) {
        if (item.selected) {
            _result += '"' + item.id + '":1';
        } else {
            _result += '"' + item.id + '":0';
        }
        _result += ',';
    }
    _result += '"other_details":"' + information.otherDetails + '"';
    _result += '}';
    console.log("result ==> ", _result);
    return _result;
}
