export enum ClothingMutationTypes {
    SET_CLOTHINGS_REF = "SET_CLOTHINGS_REF",
    ADD_CLOTHING_DETAILS = "ADD_CLOTHING_DETAILS",
    SET_ACTIVE_CLOTHING_DETAILS = "SET_ACTIVE_CLOTHING_DETAILS",
    ADD_TO_CART = "ADD_TO_CART",
    REMOVE_FROM_CART = "REMOVE_FROM_CART",
    SET_ACTIVE_ITEM_ID = "SET_ACTIVE_ITEM_ID",
    SET_USER_ON_CART = "SET_USER_ON_CART",
    SET_CUSTOMER_ON_CART = "SET_CUSTOMER_ON_CART",
    CREATE_NEW_ORDER = "CREATE_NEW_ORDER",
    SET_ACTIVE_CART = "SET_ACTIVE_CART",
    DELETE_CART = "DELETE_CART",
    DELETE_REF = "DELETE_REF",
    SET_CLOTHING_TYPES = "SET_CLOTHING_TYPES",
}