export enum UserMutationTypes {
    SET_USER = "SET_USER",
    SET_ERROR_LOGIN = "SET_ERROR_LOGIN",
    SET_SHOP = "SET_SHOP",
    SET_SHOP_USERS = "SET_SHOP_USERS",
    SET_TOKEN = "SET_TOKEN",
    SET_SHOPS = "SET_SHOPS",
    ADD_USER = "ADD_USER",
    UPDATE_USER = "UPDATE_USER",
    LOAD_USERS = "LOAD_USERS",
    DELETE_USERS = "DELETE_USERS",
    RESET_USERS = "RESET_USERS",
}