export enum NavigationActionTypes {
    NAVIGATE_FORWARD = "NAVIGATE_FORWARD",
    NAVIGATE_BACKWARD = "NAVIGATE_BACKWARD",
    NAVIGATE_TO_PREVIOUS = "NAVIGATE_TO_PREVIOUS",
    NAVIGATE_FORWARD_FROM_THE_BEGINNING = "NAVIGATE_FORWARD_FROM_THE_BEGINNING",
    RESET_NAVIGATION = "RESET_NAVIGATION",
    CHANGE_MODIFY_STATUS = "CHANGE_MODIFY_STATUS",
    SET_IS_MODIF_GOING_ON = "SET_IS_MODIF_GOING_ON",
    SET_NAVIGATION_TO_PREVIOUS = "SET_NAVIGATION_TO_PREVIOUS",
    SET_LOADING_IN_PROGRESS = "SET_LOADING_IN_PROGRESS",
    CLEAR_LOADING_IN_PROGRESS = "CLEAR_LOADING_IN_PROGRESS",
    SET_HOME_TAB_SELECTED = "SET_HOME_TAB_SELECTED",

    NAVIGATION_NAVIGATE_FORWARD = "navigation/NAVIGATE_FORWARD",
    NAVIGATION_NAVIGATE_BACKWARD = "navigation/NAVIGATE_BACKWARD",
    NAVIGATION_NAVIGATE_TO_PREVIOUS = "navigation/NAVIGATE_TO_PREVIOUS",
    NAVIGATION_RESET_NAVIGATION = "navigation/RESET_NAVIGATION",
    NAVIGATION_CHANGE_MODIFY_STATUS = "navigation/CHANGE_MODIFY_STATUS",
    NAVIGATION_SET_IS_MODIF_GOING_ON = "navigation/SET_IS_MODIF_GOING_ON",
    NAVIGATION_SET_NAVIGATION_TO_PREVIOUS = "navigation/SET_NAVIGATION_TO_PREVIOUS",
    NAVIGATION_NAVIGATE_FORWARD_FROM_THE_BEGINNING = "navigation/NAVIGATE_FORWARD_FROM_THE_BEGINNING",
    NAVIGATION_SET_LOADING_IN_PROGRESS = "navigation/SET_LOADING_IN_PROGRESS",
    NAVIGATION_CLEAR_LOADING_IN_PROGRESS = "navigation/CLEAR_LOADING_IN_PROGRESS",
    NAVIGATION_SET_HOME_TAB_SELECTED = "navigation/SET_HOME_TAB_SELECTED",
}