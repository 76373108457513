import { IAdjustment } from "@/types/IAdjustment.interface";
import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase } from "@/types/IPurchase.interface";


export type State = {
    activeAdjustment: IAdjustment;
    //adjustments: IAdjustment[];
    //filteredPurchasesAdjustment: IPurchase[];
    isFilterActive: boolean;
    purchaseAdjustmentFilters: IFilterPurchase[];
    adjustmentFromPurchaseId: IAdjustment;
    filterAndSearchAdjustment: IFilterAndSearchPurchase,
}

export const state: State = {
    activeAdjustment: {} as IAdjustment,
    //adjustments: [] as IAdjustment[],
    //filteredPurchasesAdjustment: [] as IPurchase[],
    isFilterActive: false,
    purchaseAdjustmentFilters: [] as IFilterPurchase[],
    adjustmentFromPurchaseId: {} as IAdjustment,
    filterAndSearchAdjustment: {
        filters: [],
        searchInput: '',
        nbSearch: 0,
    },
}