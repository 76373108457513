import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { ColorActionTypes as ActionTypes } from "./action-types";
import { ColorMutationTypes as MutationTypes } from "./mutation-types";
import { IColor } from "@/types/IColor";
import colorService from "@/services/color.service";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [ActionTypes.LOAD_COLORS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [ActionTypes.RESET_COLORS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [ActionTypes.LOAD_COLORS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      await loadStocks(augmentedActionContext);
    },
    async [ActionTypes.RESET_COLORS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      augmentedActionContext.commit(MutationTypes.LOAD_COLORS, [] as IColor[]);
    },
}

async function loadStocks(augmentedActionContext: AugmentedActionContext): Promise<void> {
  const colors = await colorService.loadColors();
  augmentedActionContext.commit(MutationTypes.LOAD_COLORS, colors);
}