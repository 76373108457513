import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-163a2544"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modale-overlay" }
const _hoisted_2 = { class: "modale-card d-flex flex-column p-4 extra-small-gap" }
const _hoisted_3 = { class: "modale-text" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-column w-100"
}
const _hoisted_6 = { class: "d-flex flex-row justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.message), 1),
      (_ctx.arrayDatas)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.arrayDatas)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayDatas, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.key,
                class: "d-flex flex-row w-100 small-gap justify-content-between"
              }, [
                _createElementVNode("span", null, _toDisplayString(item.key), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.getDateFormated(item.value)), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.textButtonValidation)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-primary modale-button mx-2",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.validation && _ctx.validation(...args)))
            }, _toDisplayString(_ctx.textButtonValidation), 1))
          : _createCommentVNode("", true),
        (_ctx.textButtonCancel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "btn btn-secondary modale-button cancel-button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, _toDisplayString(_ctx.textButtonCancel), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}