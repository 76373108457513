import { ICustomer, ICustomerReferential } from "@/types/ICustomer.interface"
import { IFilterAndSearchPurchase } from "@/types/IPurchase.interface"

export type State = {
    activeCustomer: ICustomer | undefined,
    referentialHeight: ICustomerReferential[],
    referentialWeight: ICustomerReferential[],
    referentialShoesSize: ICustomerReferential[],
    referentialOrigin: ICustomerReferential[],
    referentialMotivation: ICustomerReferential[],
    searchCustomer: IFilterAndSearchPurchase,
}

export const state: State = {
    activeCustomer: undefined,
    referentialHeight: [] as ICustomerReferential[],
    referentialWeight: [] as ICustomerReferential[],
    referentialShoesSize: [] as ICustomerReferential[],
    referentialOrigin: [] as ICustomerReferential[],
    referentialMotivation: [] as ICustomerReferential[],
    searchCustomer: {
        filters: [],
        searchInput: '',
        nbSearch: 0,
    },
}