
import { NavigationActionTypes } from "@/store/modules/navigation/action-types";
import { defineComponent, onBeforeUnmount, onUnmounted } from "vue";

export default defineComponent({
    name: "TheLoaderFullPage",
    methods: {
        close() {
            this.$store.dispatch(NavigationActionTypes.NAVIGATION_CLEAR_LOADING_IN_PROGRESS);
        }
    },
    unmounted() {
        this.$store.dispatch(NavigationActionTypes.NAVIGATION_CLEAR_LOADING_IN_PROGRESS);
    }
})
