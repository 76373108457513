import constantes from "@/constantes/constantes";
import { IFilterPurchase, IPurchase, IPurchaseCommentsPayload, IPurchaseDetail, IPurchaseDetailsReceive, IPurchaseSaveStatus, IPurchaseStatus } from "@/types/IPurchase.interface";
import toolsService from "./tools.service";
import { store } from '../store/index';
import { IShop } from "@/types/IShop.interface";

const apiUrlPurchase = toolsService.getApiUrl() + "v1/purchase";

export default {
    async loadPurchases(): Promise<IPurchase[]> {
        if (toolsService.isUserConnected() && toolsService.isShopSelected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            const result: IPurchase[] = await toolsService.fetchRessource(apiUrlPurchase, requestOptions, {}, "purchase.service.ts", "loadPurchases");
            return result.filter(d => d.active === true);
        } else {
            return new Promise(resolve => resolve([] as IPurchase[]));
        }
    },
    async getPurchase(purchaseId: number): Promise<IPurchase> {
        const requestOptions = toolsService.getRequestOption("GET", "");
        const apiUrlPurchaseV2 = toolsService.getApiUrl() + "v2/purchase";
        const promisePurchase = toolsService.fetchRessource(apiUrlPurchaseV2 + "/" + purchaseId, requestOptions, {}, "purchase.service.ts", "getPurchase") as Promise<IPurchase>;
        // we change that request to call at the same time different ressources and recreate the needed object

        const promisePurchaseDetails = toolsService.fetchRessource(apiUrlPurchaseV2 + "/purchaseDetails/" + purchaseId, requestOptions, {}, "purchase.service.ts", "getPurchase") as Promise<IPurchaseDetail[]>;
        const [purchase, purchaseDetails] = await Promise.all([
            promisePurchase, promisePurchaseDetails
        ]);
        console.log("await all", purchase, purchaseDetails);
        purchase.purchaseDetails = purchaseDetails;
        return new Promise(resolve => {
            resolve(purchase);
        });
    },
    async createPurchase(purchase: IPurchase): Promise<IPurchase> {
        const _purchase = formatPurchaseToCreate(purchase);
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(_purchase));
        return toolsService.fetchRessource(apiUrlPurchase, requestOptions, purchase, "purchase.service.ts", "createPurchase");
    },
    async updatePurchase(purchase: IPurchase): Promise<IPurchase> {
        for (const detail of purchase.purchaseDetails) {
            detail.purchaseId = purchase.id;
        }
        const _purchase = formatPurchaseToUpdate(purchase);
        console.log("##### savePurchase", _purchase);
        const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(_purchase));
        return toolsService.fetchRessource(apiUrlPurchase + "/" + purchase.id, requestOptions, purchase, "purchase.service.ts", "createPurchase");
    },
    async getFilteredPurchases(filters: IFilterPurchase[], searchInput: string): Promise<IPurchase[]> {
        if (toolsService.isUserConnected() && toolsService.isShopSelected()) {
            let slugs = "";
            for (const f of filters) {
                if (f.isSelected && f.type === "status") {
                    slugs += slugs === "" ? f.idSlug : ","+f.idSlug;
                }
            }

            // const input = {
            //     isSav: filters.find(f => f.slug === "isSav")?.isSelected ? true : null,
            //     isUrgent: filters.find(f => f.slug === "isUrgent")?.isSelected ? true : null,
            //     shopId: (store.getters["user/currentShop"] as IShop).id,
            //     purchaseStatusSlug: slugs
            // };

            const input = {
                isSav: filters.find(f => f.slug === "isSav")?.isSelected ? true : false,
                isUrgent: filters.find(f => f.slug === "isUrgent")?.isSelected ? true : false,
                isAlert: filters.find(f => f.slug === "isAlert")?.isSelected ? true : false,
                //shopId: (store.getters["user/currentShop"] as IShop).id,
                purchaseStatus: slugs,
                body: searchInput,
            };

            const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(input));
            const result: IPurchase[] = await toolsService.fetchRessource(apiUrlPurchase + "/search", requestOptions, filters, "purchase.service.ts", "getFilteredPurchases");
            return result.filter(d => d.active === true);
        } else {
            return new Promise(resolve => resolve([] as IPurchase[]));
        }
    },
    async getPurchaseStatus(): Promise<IPurchaseStatus[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/referential/purchase-status", requestOptions, {}, "purchase.service.ts", "getPurchaseStatus");
        } else {
            return new Promise(resolve => resolve([] as IPurchaseStatus[]));
        }
    },
    async savePurchaseStatus(payload: IPurchaseSaveStatus): Promise<boolean> {
        try {
            const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(payload));
            const test = await toolsService.fetchRessource(apiUrlPurchase + "/status", requestOptions, payload, "purchase.service.ts", "savePurchaseStatus");
            console.log("savePurchaseStatus => ", test);
            
            return new Promise((resolve) => resolve(true));
        } catch(error) {
            return new Promise((resolve) => resolve(false));
        }
    },
    async receivePurchaseDetail(payload: IPurchaseDetailsReceive): Promise<void> {
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(payload));
        return toolsService.fetchRessource(apiUrlPurchase + "/receipt", requestOptions, payload, "purchase.service.ts", "receivePurchaseDetail");
    },
    async fetchPurchaseStatus(payload: number): Promise<IPurchaseDetail> {
        const requestOptions = toolsService.getRequestOption("POST", "");
        return toolsService.fetchRessource(apiUrlPurchase + "/fetch-detail-status/" + payload, requestOptions, payload, "purchase.service.ts", "fetchPurchaseStatus");
    },
    async deletePurchase(payload: IPurchase): Promise<boolean> {
        const requestOptions = toolsService.getRequestOption("DELETE", "");
        return toolsService.fetchRessource(apiUrlPurchase + "/" + payload.id, requestOptions, payload, "purchase.service.ts", "deletePurchase").then(() => true).catch(error => false);
    },
    async updateComments(payload: IPurchaseCommentsPayload): Promise<boolean> {
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(payload));
        try {
            const result = await toolsService.fetchRessource(apiUrlPurchase + "/comment", requestOptions, payload, "purchase.service.ts", "updateComments");
            if (result && result.message && result.message !== '') {
                return new Promise((resolve) => resolve(false));
            }
            return new Promise((resolve) => resolve(true));
        } catch(error) {
            return new Promise((resolve) => resolve(false));
        }
        
    }
}

function formatPurchaseToCreate(purchase: IPurchase): unknown {
    const _purchase: unknown = {
        id: purchase.id,
        productionDate: null,
        fittingDate: null,
        cause: purchase.isOld ? "oldPurchase" : purchase.cause,
        deadlineDate: purchase.deadlineDate !== ""? purchase.deadlineDate : null,
        customerId: purchase.customerId,
        userId: purchase.userId,
        shopId: purchase.shopId,
        isOld: purchase.isOld,
        isSav: purchase.isSav ? true: false,
        isUrgent: purchase.isUrgent? true: false,
        isComingLater: purchase.isComingLater,
        isWaitingPayment: purchase.isWaitingPayment,
        comments: purchase.comments? purchase.comments: ""
    };
    return _purchase;
}

function formatPurchaseToUpdate(purchase: IPurchase): unknown {
    const _purchase: unknown = {
        id: purchase.id,
        productionDate: purchase.productionDate !== "" ? purchase.productionDate: null,
        fittingDate: purchase.fittingDate !== "" ? purchase.fittingDate: null,
        cause: purchase.isOld ? "oldPurchase" : purchase.cause,
        deadlineDate: purchase.deadlineDate !== "" ? purchase.deadlineDate : null,
        customerId: purchase.customerId,
        userId: purchase.userId,
        shopId: purchase.shopId,
        isOld: purchase.isOld,
        isSav: purchase.isSav ? true : false,
        isUrgent: purchase.isUrgent ? true : false,
        comments: purchase.comments ? purchase.comments : "",
        purchaseDetails: getPurchaseDetailsFormated(purchase.purchaseDetails),
        purchaseToPurchaseStatuses: purchase.purchaseToPurchaseStatuses,
        isComingLater: purchase.isComingLater,
        isWaitingPayment: purchase.isWaitingPayment,
    };
    return _purchase;
}

function getPurchaseDetailsFormated(input: IPurchaseDetail[]) : IPurchaseDetail[] {
    //console.log("getPurchaseDetailsFormated => ", input);
    const result = JSON.parse(JSON.stringify(input)) as IPurchaseDetail[];
    for (const item of result) {
        item.clothingOptionToPurchaseDetail = item.clothingOptionToPurchaseDetail.filter(c => c.value !== "")
    }
    //console.log(result);
    return result;
}