
import {DialogEvents} from "@/constantes/events";
import { defineComponent } from "vue";

export default defineComponent({
    name: "TheDialog",
    props: {
        message: {
            type: String,
            required: true
        },
        textButtonValidation: {
            type: String,
            required: false
        },
        textButtonCancel: {
            type: String,
            required: false
        },
        arrayDatas: {
            required: false,
        }
    },
    emits: [DialogEvents.DIALOG_CLOSE_CANCEL, DialogEvents.DIALOG_CLOSE_VALID],
    methods: {
        getDateFormated(date: string) {
            if (date && date !== "" && date !== "undefined" && date !== "null") {
                const _date = new Date(date);    
                return _date.toLocaleDateString() + " " +  _date.toLocaleTimeString();
            } else {
                return "";
            }
        },
        validation() {
            this.$emit(DialogEvents.DIALOG_CLOSE_VALID);
        },
        cancel() {
            this.$emit(DialogEvents.DIALOG_CLOSE_CANCEL);
        }
    }
})
